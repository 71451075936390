import React from 'react'

import {Button} from '@daedalus/atlas/Button'
import {Divider} from '@daedalus/atlas/Divider'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  noMoreReviews: boolean
  isLoading?: boolean
  onLoadMore: () => void
  sourceComponent: string
}
export const LoadMoreReviews: React.FC<Props> = ({
  noMoreReviews,
  isLoading = false,
  onLoadMore,
  sourceComponent
}) => {
  const dispatchTeamEvent = useDispatchTeamEvent()

  const handleLoadMore = () => {
    dispatchTeamEvent(
      trackEvent({
        category: Category.User,
        entity: Entity.ReviewDetailsLoadMoreButton,
        action: Action.Clicked,
        payload: {
          component: sourceComponent
        }
      })
    )
    onLoadMore()
  }
  if (noMoreReviews)
    return (
      <>
        <Divider />
        <Box
          textAlign="center"
          paddingBottom="s700"
          color="content.neutral.c600"
        >
          <Text variant="bodyS">
            <FormattedMessageWrapper
              id="noMoreReviews"
              defaultMessage="No more reviews to show"
            />
          </Text>
        </Box>
      </>
    )

  return (
    <>
      <Divider />
      <Button
        loading={isLoading}
        variant="secondary"
        iconStart={<Icon name="ArrowDown" />}
        onClick={handleLoadMore}
      >
        <FormattedMessageWrapper id="loadMore" defaultMessage="Load more" />
      </Button>
      <Box paddingBottom="s700" />
    </>
  )
}
