import {createSlice} from '@reduxjs/toolkit'

import {getCookie} from '@daedalus/core/src/_web/utils/cookies'

import type {PayloadAction} from '@reduxjs/toolkit'

interface EmailSignUpState {
  isOpen: boolean
  isEmailVerified: boolean
}

const initialState = {
  isOpen: false,
  isEmailVerified: getCookie('isEmailVerified') === 'true'
} as EmailSignUpState

const {actions, reducer} = createSlice({
  name: 'emailSignUp',
  initialState,
  reducers: {
    setEmailSignUpVisibility(state, action: PayloadAction<boolean>) {
      state.isOpen = action.payload
    }
  }
})

export default reducer

export const {setEmailSignUpVisibility} = actions
