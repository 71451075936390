import {loadModule} from 'utils'

import type {ReviewSortingMobileContentProps} from './ReviewSortingMobileContent'

export const ReviewSortingMobileContentLoadable =
  loadModule<ReviewSortingMobileContentProps>(
    async () =>
      import(
        /* webpackChunkName: "ReviewSortingMobileContent" */
        './ReviewSortingMobileContent'
      ),
    {}
  )
