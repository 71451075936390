import {Metric, MetricType} from 'web-vitals'

import {
  FilterParameters,
  Hotel,
  MatchedDim,
  Offer,
  Promos,
  SplitBookingBundle as SapiSplitBookingBundle,
  SplitBookingOffer
} from '@findhotel/sapi'

import {
  BookingStatus,
  Source as BookingSource
} from '../../api-types/bovio/response/booking'
import {CognitoError} from '../../auth/types/Cognito'
import {SuggestionItemDataType} from '../../autocomplete/types'
import {ErrorDetails} from '../../booking/types/ErrorDetails'
import {ErrorResponse} from '../../booking/types/ErrorResponse'
import {CancellationPenalty} from '../../offer/types/offer'
import {UnifiedOfferCheckData} from '../../offer/types/OfferCheck'
import {Price} from '../../price/business/price'
import {RoomSplitBookingContext} from '../../room/types/room'
import {Room} from '../../room/types/room'

// @todo (@SAPI): move this type/s to the sapi package and import like Offer and Hotel above instead
export type LovHit = {
  categoryID?: number
  id: number
  objectID: string
  value?: string | Record<string, string>
  name?: string | Record<string, string>
}

export enum Source {
  dealFreezeConfirmationPage = 'deal_freeze_confirmation_page',
  menuBar = 'menu_bar',
  mail = 'mail'
}

export type Lov = Record<string, LovHit>

interface SearchLocation {
  country?: string
  region?: string
  city?: string
}

export type AnalyticsContextPayload =
  | MarketingContext
  | SearchContext
  | UserParametersContext
  | HotelContext
  | SearchResultsContext
  | ErrorContext
  | HotelInfoContext
  | PaginationContext
  | PerformanceMeasuredContext
  | SearchHistoryContext
  | AutocompleteContext
  | ExperimentContext
  | CookieConsentContext
  | LeadContext
  | PriceContext
  | CheckoutOfferContext
  | SearchOfferContext
  | BookingContext
  | ServerErrorContext
  | NpsContext
  | CoreWebVitalsContext
  | BookingContext[]
  | null
  | ParametrizedEventContexts
  | MarketingAutomationContext
  | WidgetSearchContext
  | RoomContext
  | RoomContextList
  | RoomOfferContext
  | RoomSplitBookingContext

export type AnalyticsContextList = Partial<
  Record<AnalyticsContext, AnalyticsContextPayload>
>

export interface TrackEventPayload {
  category: Category
  entity: Entity
  action: Action
  page?: Page | null
  payload?: Record<string, unknown>
  component?: string
  analyticsContext?: AnalyticsContextList
  team?: Team
  id?: string
  metric?: string
  value?: number
  meta?: Record<string, string>
  unit?: string
}

export interface TrackEventProperties extends TrackEventPayload {
  name?: string
}

/**
 * Team - The team that trigger the event
 * The team name should match the environment variable name which holds the analytics API key and endpoint URL
 * For example, the `REACT_APP_ANALYTICS_RETENTION_API_KEY` environment variable keeps the API key for
 * the `retention` team.
 * The `Default` is used when the team name is not provided in the trackEvent call. Its value is different for search
 * and checkout projects as each of these projects has its own default team.
 */
export enum Team {
  Default = 'default',
  Retention = 'retention',
  Select = 'select',
  PowerFeatures = 'power_features',
  Search = 'search',
  MobileApp = 'mobile-app',
  CoreWebVitals = 'core_web_vitals'
}

/**
 * Category - what is triggering the event.
 * Do not add to this list.
 */
export enum Category {
  System = 'System',
  User = 'User',
  Validation = 'Validation'
}

/**
 * Entity - what thing did it involve.
 * Add to this list when a new entity is required.
 */
export enum Entity {
  /**
   * Website wide entities
   */
  AppBanner = 'AppBanner',
  Authentication = 'Authentication',
  GoogleOneTap = 'GoogleOneTap',
  Booking = 'Booking',
  Errored = 'Errored',
  Experiment = 'Experiment',
  DatePicker = 'DatePicker',
  DatePickerInput = 'DatePickerInput',
  DatePickerDay = 'DatePickerDay',
  DatePickerSubmitButton = 'DatePickerSubmitButton',
  DatePickerClearDatesButton = 'DatePickerClearDatesButton',
  Page = 'Page',
  FetchOptimizelyDataFile = 'FetchOptimizelyDataFile',
  CookieBanner = 'CookieBanner',
  CookieConsent = 'CookieConsent',
  CookieConsentManagement = 'CookieConsentManagement',
  Signup = 'Signup',
  Login = 'Login',
  CookieBannerScript = 'CookieBannerScript',
  CustomerIoScript = 'CustomerIoScript',
  FullScreenImageGallery = 'FullScreenImageGallery',
  VerticalImageGallery = 'VerticalImageGallery',
  ImageGallery = 'ImageGallery',
  PasswordlessSend = 'PasswordlessSend',
  Screenshot = 'Screenshot',
  NpsSurvey = 'NpsSurvey',
  NpsScore = 'NpsScore',
  OptimizeRoomsInfo = 'OptimizeRoomsInfo',
  ResendEmailButton = 'ResendEmailButton',
  BookingHistoryPage = 'BookingHistoryPage',
  BookingDetailsButton = 'BookingDetailsButton',
  NavigationTab = 'NavigationTab',
  SupportChat = 'SupportChat',
  MenuItem = 'MenuItem',
  BackButton = 'BackButton',
  LanguageSelectorSearchInput = 'LanguageSelectorSearchInput',
  CurrencySelectorSearchInput = 'CurrencySelectorSearchInput',
  LanguageCurrencyPreferencesTab = 'LanguageCurrencyPreferencesTab',
  Picker = 'Picker',
  PriceTimeOut = 'PriceTimeOut',
  InvoiceService = 'InvoiceService',
  CustomerSupportHelp = 'CustomerSupportHelp',
  CustomerSupportOverlay = 'CustomerSupportOverlay',
  PrivacyPolicy = 'PrivacyPolicy',
  OurTerms = 'OurTerms',
  ProviderTerms = 'ProviderTerms',
  /**
   * Search specific entities
   */
  AppExclusiveOffer = 'AppExclusiveOffer',
  Search = 'Search',
  Offer = 'Offer',
  LockedOffer = 'LockedOffer',
  SearchSummary = 'SearchSummary',
  SearchBox = 'SearchBox',
  SearchBoxSearchButton = 'SearchBoxSearchButton',
  SearchBoxShadow = 'SearchBoxShadow',
  PreferencesOverlay = 'PreferencesOverlay',
  MembershipOverlay = 'MembershipOverlay',
  AccountOverlay = 'AccountOverlay',
  ReferralOverlay = 'ReferralOverlay',
  GuestPicker = 'GuestPicker',
  FilterOptions = 'FilterOptions',
  HotelDetails = 'HotelDetails',
  HotelDetailsOverlay = 'HotelDetailsOverlay',
  HomePage = 'HomePage',
  HomePageSearch = 'HomePageSearch',
  SortByOverlay = 'SortByOverlay',
  PriceFilterOverlay = 'PriceFilterOverlay',
  FacilitiesFilterOverlay = 'FacilitiesFilterOverlay',
  DestinationPicker = 'DestinationPicker',
  DestinationPickerInput = 'DestinationPickerInput',
  GuestPickerInput = 'GuestPickerInput',
  GuestPickerSubmitButton = 'GuestPickerSubmitButton',
  PopupOnExit = 'PopupOnExit',
  LoadMoreResultsButton = 'LoadMoreResultsButton',
  SearchResultsEnd = 'SearchResultsEnd',
  SearchResultsPageSearch = 'SearchResultsPageSearch',
  SearchResultsPageEnd = 'SearchResultsPageEnd',
  DestinationPickerSearchHistoryItem = 'DestinationPickerSearchHistoryItem',
  DestinationPickerAutocompleteItem = 'DestinationPickerAutocompleteItem',
  DestinationPickerNearbyOption = 'DestinationPickerNearbyOption',
  DestinationPickerAutoCompleteItems = 'DestinationPickerAutoCompleteItems',
  BackToTopButton = 'BackToTopButton',
  FilterButton = 'FilterButton',
  FloatingSearchControls = 'FloatingSearchControls',
  GeolocationCoordinatesFetch = 'GeolocationCoordinatesFetch',
  MapViewButton = 'MapViewButton',
  MapViewToggle = 'MapViewToggle',
  MapViewCloseButton = 'MapViewCloseButton',
  MapViewExpander = 'MapViewExpander',
  MapView = 'MapView',
  MapViewBlankSpace = 'MapViewBlankSpace',
  MapViewHotelPin = 'MapViewHotelPin',
  MapBox = 'MapBox',
  MapViewSearchAreaButton = 'MapViewSearchAreaButton',
  MapTypeButton = 'MapTypeButton',
  MapLayersButton = 'MapLayersButton',
  MapExploreButton = 'MapExploreButton',
  HotelCard = 'HotelCard',
  HotelCardRemove = 'HotelCardRemove',
  DetailsOverlayCloseButton = 'DetailsOverlayCloseButton',
  DetailsOverlayShadow = 'DetailsOverlayShadow',
  HotelCardAmenityTags = 'HotelCardAmenityTags',
  DeleteAccountOverlay = 'DeleteAccountOverlay',
  DeleteAccountCancel = 'DeleteAccountCancel',
  DeleteAccountConfirm = 'DeleteAccountConfirm',
  LanguageCurrencyPreferencesButton = 'LanguageCurrencyPreferencesButton',
  CurrencyChange = 'CurrencyChange',
  LanguageChange = 'LanguageChange',
  LanguageCurrencyPreferencesDropdownMenu = 'LanguageCurrencyPreferencesDropdownMenu',
  MagicLinkCode = 'MagicLinkCode',
  MagicLinkSend = 'MagicLinkSend',
  MagicLinkResend = 'MagicLinkResend',
  CongratulationsOverlay = 'CongratulationsOverlay',
  FilterBarFilterButton = 'FilterBarFilterButton',
  FilterPreference = 'FilterPreference',
  FilterBarScrollButton = 'FilterBarScrollButton',
  SortPreference = 'SortPreference',
  SortButton = 'SortButton',
  AvailabilityPaneTile = 'AvailabilityPaneTile',
  AvailabilityPaneSeeMore = 'AvailabilityPaneSeeMore',
  AvailabilityCalendarDay = 'AvailabilityCalendarDay',
  MobileDealPopUp = 'MobileDealPopUp',
  PriceToggle = 'PriceToggle',
  OfferTag = 'OfferTag',
  AnchorHotelAvailablePane = 'AnchorHotelAvailablePane',
  LocationAccessPreference = 'LocationAccessPreference',
  SplitBookingOffers = 'SplitBookingOffers',
  MobileAllOffersSplitBookingOffersModal = 'MobileAllOffersSplitBookingOffersModal',
  SplitBookingInfoIcon = 'SplitBookingInfoIcon',
  GuestConfigPreference = 'GuestConfigPreference',
  PriceDisplayPreference = 'PriceDisplayPreference',
  ReviewScoreFilterOverlay = 'ReviewScoreFilterOverlay',
  StarRatingsFilterOverlay = 'StarRatingsFilterOverlay',
  PropertyTypeFilterOverlay = 'PropertyTypeFilterOverlay',
  AppInstallPopUpStoreLinks = 'AppInstallPopUpStoreLinks',
  AppInstallButton = 'AppInstallButton',
  AppInstallBottomSheetClose = 'AppInstallBottomSheetClose',
  AppInstallBottomSheet = 'AppInstallBottomSheet',
  AppInstallLink = 'AppInstallLink',
  ColorPriceCalendarAvailability = 'ColorPriceCalendarAvailability',
  NoRoomsAvailableMessage = 'NoRoomsAvailableMessage',
  SplashScreen = 'SplashScreen',
  PartialResultsBanner = 'PartialResultsBanner',
  ShowNearestResults = 'ShowNearestResults',
  ReviewOverlay = 'ReviewOverlay',
  ReviewDetails = 'ReviewDetails',
  ReviewSort = 'ReviewSort',
  RoomImage = 'RoomImage',
  ReviewsFetch = 'ReviewsFetch',
  DestinationPickerFreeTextSearchItem = 'DestinationPickerFreeTextSearchItem',
  HotelCardImageCarousel = 'HotelCardImageCarousel',
  SearchDateToolTip = 'SearchDateToolTip',
  SapiPreheat = 'SapiPreheat',
  ReviewsTranslate = 'ReviewsTranslate',
  PriceRefresh = 'PriceRefresh',
  ReviewDetailsLoadMoreButton = 'ReviewDetailsLoadMoreButton',
  Skeleton = 'Skeleton',

  /**
   * Checkout specific entities
   */
  CancellationTermsLabel = 'CancellationTermsLabel',
  PaymentPage = 'PaymentPage',
  PaymentButton = 'PaymentButton',
  PaymentFormInput = 'PaymentFormInput',
  ConfirmationPage = 'ConfirmationPage',
  MembershipActivate = 'MembershipActivate',
  MembershipStatus = 'MembershipStatus',
  MembershipExplore = 'MembershipExplore',
  ItineraryPage = 'ItineraryPage',
  CheckInInstructions = 'CheckInInstructions',
  DueAtProperty = 'DueAtProperty',
  TaxesAndFees = 'TaxesAndFees',
  LocalTax = 'LocalTax',
  ResortFees = 'ResortFees',
  OtherFees = 'OtherFees',
  PropertyFees = 'PropertyFees',
  PersonalDetails = 'PersonalDetails',
  BillingDetails = 'BillingDetails',
  PaymentDetails = 'PaymentDetails',
  PaymentTypeChange = 'PaymentTypeChange',
  Cancellation = 'Cancellation',
  BookAgainButton = 'BookAgainButton',
  ManageBookingButton = 'ManageBookingButton',
  TrustpilotWidget = 'TrustpilotWidget',
  ReviewOnTPButton = 'ReviewOnTPButton',
  GemsSavingsBanner = 'GemsSavingsBanner',
  FeesDetailsButton = 'FeesDetailsButton',
  CancellationDetailsButton = 'CancellationDetailsButton',
  ChargeLaterDetailsButton = 'ChargeLaterDetailsButton',
  RoomDetailsButton = 'RoomDetailsButton',
  GuestDetails = 'GuestDetails',
  SplitBookingDetails = 'SplitBookingDetails',
  PriceTimerDetails = 'PriceTimerDetails',
  TextboxAutofill = 'TextboxAutofill',
  PaymentMethods = 'PaymentMethods',
  OfflineStatusMessage = 'OfflineStatusMessage',
  GuestType = 'GuestType',
  SpecialRequestButton = 'SpecialRequestButton',
  ScrollDownButton = 'ScrollDownButton',
  RedirectOffer = 'RedirectOffer',
  RedirectOffersBox = 'RedirectOffersBox',
  /**
   * Select specific entities
   */
  HotelDetailsStaticOfferCTA = 'HotelDetailsStaticOfferCTA',
  HotelMoreDealsOption = 'HotelMoreDealsOption',
  RoomDetailsAndOptions = 'RoomDetailsAndOptions',
  //added as part of sapi4eva-hotel-descriptions
  HotelDetailsDescription = 'HotelDetailsDescription',
  HotelDetailsReviewsSection = 'HotelDetailsReviewsSection',
  HotelDetailsLocationSummary = 'HotelDetailsLocationSummary',
  HotelDetailsReviewSummary = 'HotelDetailsReviewSummary',
  HotelDetailsFacilitiesSection = 'HotelDetailsFacilitiesSection',
  HotelDetailsSeeMoreFacilities = 'HotelDetailsSeeMoreFacilities',
  HotelDetailsPropertyPolicySection = 'HotelDetailsPropertyPolicySection',
  HotelDetailsPropertyPolicy = 'HotelDetailsPropertyPolicy',
  HotelDetailsLocationSection = 'HotelDetailsLocationSection',
  HotelDetailsStreetView = 'HotelDetailsStreetView',
  SoldOutChangeDatesButton = 'SoldOutChangeDatesButton',
  HotelDetailsBackButton = 'HotelDetailsBackButton',
  HotelDetailsTab = 'HotelDetailsTab',
  HotelDetailsGallery = 'HotelDetailsGallery',
  HotelDetailsMapViewInteraction = 'HotelDetailsMapViewInteraction',
  HotelSave = 'HotelSave',
  HotelSaveMessage = 'HotelSaveMessage',
  HotelSaveMessageViewButton = 'HotelSaveMessageViewButton',
  HotelSaveMessageCloseButton = 'HotelSaveMessageCloseButton',
  ShareLinkButton = 'ShareLinkButton',
  OverlayClose = 'OverlayClose',
  BrowserBackButton = 'BrowserBackButton',
  HotelSaveMessageChangeButton = 'HotelSaveMessageChangeButton',
  PersuasionBannerCloseButton = 'PersuasionBannerCloseButton',
  OfferFilterPreference = 'OfferFilterPreference',
  HotelDetailsSimilarHotelsSection = 'HotelDetailsSimilarHotelsSection',
  SeeMoreSimilarProperties = 'SeeMoreSimilarProperties',
  SimilarHotels = 'SimilarHotels',
  SeeMoreRoomsButton = 'SeeMoreRoomsButton',
  RoomImageGallery = 'RoomImageGallery',
  RoomDetailsOverlay = 'RoomDetailsOverlay',
  RoomOfferFilterPreference = 'RoomOfferFilterPreference',
  RoomOffer = 'RoomOffer',
  RoomSearch = 'RoomSearch',
  LockedRoomOffer = 'LockedRoomOffer',
  PriceDetailButton = 'PriceDetailButton',
  PriceDetail = 'PriceDetail',
  CancellationPolicyDetailButton = 'CancellationPolicyDetailButton',
  CancellationPolicyDetail = 'CancellationPolicyDetail',
  SearchResultTimeoutAlert = 'SearchResultTimeoutAlert',
  Web2AppPriceDifferencePopUp = 'Web2AppPriceDifferencePopUp',
  SearchResultTimeoutAlertTryAgainButton = 'SearchResultTimeoutAlertTryAgainButton',

  APOverlay = 'APOverlay',
  APStandalone = 'APStandalone',
  AccommodationPage = 'AccommodationPage',
  SelectedDeal = 'SelectedDeal',
  /**
   * Membership/Gems specific entities
   */
  AccountEmail = 'AccountEmail',
  SuggestedProperties = 'SuggestedProperties',
  Carousel = 'Carousel',
  CarouselCard = 'CarouselCard',
  SuggestedLocation = 'SuggestedLocations',
  GemsOfferBottomSheet = 'GemsOfferBottomSheet',
  GemsDealTooltip = 'GemsDealTooltip',
  CarouselSeeAll = 'CarouselSeeAll',
  GemsCarousel = 'GemsCarousel',
  AppLink = 'AppLink',
  CheapestAnchorOffer = 'CheapestAnchorOffer',
  PriceAlertToggle = 'PriceAlertToggle',
  PriceAlertEnable = 'PriceAlertEnable',
  PriceAlertToast = 'PriceAlertToast',
  PriceAlertBanner = 'PriceAlertBanner',
  SubscribeToPush = 'SubscribeToPush',
  AuthenticationOverlay = 'AuthenticationOverlay',
  ACL = 'ACL',
  SMS = 'SMS',
  SMSOverlay = 'SMSOverlay',
  OTP = 'OTP',
  SubscribeToPriceWatch = 'SubscribeToPriceWatch',
  AlertCardUndo = 'AlertCardUndo',
  StartNewSearch = 'StartNewSearch',
  FeatureOnboarding = 'FeatureOnboarding',
  PriceMismatchViewDeal = 'PriceMismatchViewDeal',
  PriceMismatchNewSearch = 'PriceMismatchNewSearch',
  PriceMismatch = 'PriceMismatch',

  /**
   * Referral specific entities
   */
  ReferralLinkOverlay = 'ReferralLinkOverlay',
  ReferralLinkCopy = 'ReferralLinkCopy',
  ReferralMemberTierUpgrade = 'ReferralMemberTierUpgrade',
  ReferralLinkShare = 'ReferralLinkShare',
  SavingsBanner = 'SavingsBanner',
  SavingsSection = 'SavingsSection',

  /**
   * Core Web Vitals specific entities
   */
  WebVitals = 'WebVitals',

  /**
   * Mobile App specific entities
   */
  WebView = 'WebView',
  PushConsentDeny = 'PushConsentDeny',
  PushConsentAllow = 'PushConsentAllow',
  PushToggle = 'PushToggle',
  PushPermission = 'PushPermission',
  AppTrackingTransparencyPrompt = 'AppTrackingTransparencyPrompt',
  AppTrackingTransparencyPromptAllow = 'AppTrackingTransparencyPromptAllow',
  AppTrackingTransparencyPromptDeny = 'AppTrackingTransparencyPromptDeny',
  ReviewFlow = 'ReviewFlow',
  BottomBarItem = 'BottomBarItem',
  Onboarding = 'Onboarding',
  AppReview = 'AppReview',
  DeepLink = 'DeepLink',
  AppForceUpdateDialog = 'AppForceUpdateDialog',

  /**
   * Widgets specific entities
   */
  Widget = 'Widget',
  WidgetSearch = 'WidgetSearch',
  WidgetNearbyPlacesSearch = 'WidgetNearbyPlacesSearch',
  SeeAllStays = 'SeeAllStays',
  VioLogo = 'VioLogo',
  Banner = 'Banner',
  PlaceSuggestion = 'PlaceSuggestion',

  /**
   * Deal Freeze specific entities
   */
  DealFreeze = 'DealFreeze',
  DealFreezeButton = 'DealFreezeButton',
  DealFreezeOfferButton = 'DealFreezeOfferButton',
  DealFreezeOverlay = 'DealFreezeOverlay',
  DealFreezeConfirmationOverlay = 'DealFreezeConfirmationOverlay',

  /**
   * Browser specific entities
   */
  ContextMenu = 'ContextMenu',

  /**
   * SEM pages
   */
  SEMPage = 'SEMPage',
  SelectDates = 'SelectDates',
  BreadCrumb = 'BreadCrumb'
}

/**
 * Action - what did the System/User/Validation do.
 * Do not add to this list unless really necessary.
 */
export enum Action {
  Served = 'Served',
  Clicked = 'Clicked',
  Loaded = 'Loaded',
  Errored = 'Errored',
  Succeeded = 'Succeeded',
  Submitted = 'Submitted',
  Displayed = 'Displayed',
  Viewed = 'Viewed',
  PerformanceMeasured = 'PerformanceMeasured',
  MouseOut = 'MouseOut',
  MouseOvered = 'MouseOvered',
  Scrolled = 'Scrolled',
  Hidden = 'Hidden',
  Requested = 'Requested',
  Duplicated = 'Duplicated',
  Zoomed = 'Zoomed',
  Cancelled = 'Cancelled',
  Closed = 'Closed',
  Translated = 'Translated',
  Changed = 'Changed',
  Missing = 'Missing',
  Mismatched = 'Mismatched'
}

/**
 * Page - where did the event happen.
 * Do not add to this list unless really necessary.
 */
export enum Page {
  Home = 'Home',
  Search = 'Search',
  ProviderRedirect = 'ProviderRedirect',
  Account = 'Account',
  Membership = 'Membership',
  RoomSelection = 'RoomSelection',
  Payment = 'Payment',
  Confirmation = 'Confirmation',
  Itinerary = 'Itinerary',
  Accommodation = 'Accommodation',
  AlertManagement = 'AlertManagement',
  BookingHistoryPage = 'BookingHistoryPage',
  MyDealFreezePage = 'MyDealFreezePage',
  Profile = 'Profile',
  AccommodationOverlay = 'AccommodationOverlay',
  SEMSearch = 'SEMSearch',
  SearchResults = 'SearchResults'
}

/**
 * Analytics - what data is required in the event.
 * Add to this list when a new context is required.
 */
export enum AnalyticsContext {
  SegmentContext = 'segmentContext',
  UserParametersContext = 'userParametersContext',
  MarketingContext = 'marketingContext',
  SearchContext = 'searchContext',
  HotelContext = 'hotelContext',
  HotelInfoContext = 'hotelInfoContext',
  OfferContext = 'offerContext',
  SearchResultsContext = 'searchResultsContext',
  AutocompleteContext = 'autocompleteContext',
  SearchHistoryContext = 'searchHistoryContext',
  PerformanceMeasuredContext = 'performanceMeasuredContext',
  PaginationContext = 'paginationContext',
  ErrorContext = 'errorContext',
  ExperimentContext = 'experimentContext',
  CookieConsentContext = 'cookieConsentContext',
  PriceContext = 'priceContext',
  LeadContext = 'leadContext',
  BookingContext = 'bookingContext',
  ServerErrorContext = 'serverErrorContext',
  NpsContext = 'npsContext',
  RoomContext = 'roomContext',
  WidgetSearchContext = 'widgetSearchContext',
  RoomContextList = 'roomContextList',
  MarketingAutomationContext = 'marketingAutomationContext',
  SplitBooking = 'splitBooking'
}

/**
 * Enum for performance metrics
 */
export enum PerformanceMetric {
  // First Relevant Paint
  FRP = 'FRP',
  // Most Relevant Paint
  MRP = 'MRP',
  // Largest Relevant Paint
  // @deprecated - use MRP instead
  LRP = 'LRP'
}

export interface MarketingContext {
  label: string
  utmCampaign: string
  utmMedium: string
  utmSource: string
}

export interface SearchContext {
  checkIn: string
  checkOut: string
  isDefaultDates: boolean
  isNoDates: boolean
  currency: string
  locale: string
  rooms: string
  numberOfAdults: number
  numberOfChildren: number
  anchorHotelId: string
  placeId: string
  searchId: string
  isUserSearch: boolean
  isHomeSearch: boolean
  isFsmr: boolean
  isOptimizeRooms: boolean
  isMapOpen: boolean
  isHotelDetailsOpen: boolean
  sortField: string
  sortOrder: string
  filters: FilterParameters
  cugDeals?: string
  mapAreaSearchCoordinates: Array<string | number>
  searchWillRetriggerIfNoOffers: boolean // TODO (@Search) [2022-08-01] Clean up as part of c9457bea-remove-date-check-logic
  isOffersOnlyFetch: boolean
  layout: string
  priceDisplay: 'total_price' | 'nightly_price'
  searchType: string
  searchTrigger: string
  includeTaxes: boolean
  includeLocalTaxes: boolean
  searchLocation: SearchLocation
  mlos?: number
  metaCheckIn: string
  metaCheckOut: string
  address: string
  hotelsHaveStaticPosition?: boolean
  freeTextSearchString?: string
  searchBoxDestinationDisplayValue: string
}

export interface WidgetSearchContext {
  checkIn?: string
  checkOut?: string
  placeId?: string
  placeName?: string
  country?: string
  city?: string
  hotelNames?: string[]
  hotelIds?: string[]
  rooms?: string
  language?: string
  currency?: string
  userDevice?: string
  userCountry?: string
}

export interface UserParametersContext {
  deviceType: string
  userCountry: string
  userCountryRegion?: string
  viewPortHeight: number
  viewPortWidth: number
  anonymousId?: string
  deviceTime?: string
  preferredColorScheme?: string
  isReturningUser?: boolean
  firstVisit?: string
  fullStorySessionUrl?: string
  isReactNativeWebView: boolean
  currency: string
  locale: string
  visitDayCount: number
  urlLang?: string
  cookieLang?: string
  browserLang?: string
  isUserLoggedIn?: boolean
  isSmsProvided?: boolean
}

export interface SplitBookingHotelContext {
  url?: string
  bundleId?: string
  offers?: SplitBookingOffer[]
  tags?: string[]
  totalRate?: RateBreakdown
  splitBookingType: 'split_flow' | 'single_flow'
  splitBookingPosition?: number
  isHidden?: boolean
  isAppLocked?: boolean
}

export interface HotelContext {
  hotelId: string
  highestVisiblePrice: number
  hotelPosition: number
  regularPriceRange?: [number, number]
  hotelTags: string[]
  cheapestPrice: number
  isHotelMapOpen: boolean
  indexedDiscountModifier?: string
  discount?: {
    modifier: string
    hasParityProvider: boolean
    hasDiscountProvider: boolean
    discountProvider: string
  }
  distanceFromSearchedLocation: number
  isPartiallyMatched: boolean
  // added as part of sapi4eva-hotel-descriptions
  isAIDescriptionEligible?: boolean
  promos?: Promos
  splitBooking?: SplitBookingHotelContext
}

export interface HotelMetaData extends HotelContext {
  topOffers: SearchOfferContext[]
}

export interface PaginationContext {
  pageOffset: number
  pageNumber: number
  pageLength: number
}

export interface SearchResultsContext extends PaginationContext {
  algoliaNbHotels: number
  anchorHotelHasPrivateDeal: boolean
  anchorHotelHasYourChoice: boolean
  hotelIndexesWithPrivateDeals: number[]
  hotelsMetaData: HotelMetaData[]
  nbHotels: number
  staticHotelsLength: number
  staticHotelsOffset: number
  algoliaTotalNbHotels: number
  unavailableHotels: Array<Record<string, unknown>>
}

export interface HotelInfoContext {
  hotelId: string
  hotelName: string
  address: string
  checkInTime: string
  checkOutTime: string
  country: string
  lastBooked: number
  reviewCount: number
  starRating: number
  guestRating: Hotel['guestRating']
  guestType: Hotel['guestType']
  numberOfImages: number
  topAmenities: LovHit[]
  imageURIs: string[]
}

export interface ErrorContext {
  errorMessage?: string | CognitoError | Record<string, unknown>
  errorType: string
  errorCode?: string
  errorDetails?: ErrorDetails | Record<string, unknown>
  endpoint?: string
}

export interface PerformanceMeasuredContext {
  metric: string
  value: number
  unit: string
}

export type CoreWebVitalsContext = Omit<MetricType, 'name'> & {
  metric: Metric['name']
}

export interface SearchHistoryContext {
  checkIn: string
  checkOut: string
  hotelId?: string
  placeId?: string
  rooms: string
}

export interface AutocompleteContext {
  suggestion: SuggestionItemDataType
  position: number
  inputtedString?: string
}

export interface ExperimentContext {
  experimentId: string
  variationId: string
  isActive: boolean
}

export interface CookieConsentContext {
  consentId: string
  enabledCookieGroups?: string[]
  cookieGroup?: string
  enabled?: boolean
}

export interface LeadContext {
  anonymousId: string
  checkIn: string
  checkOut: string
  currency: string
  hotelId: string
  searchId: string | null
  locale: string
  providerCode: string
  providerRateType?: string
  redirectId: string
  rooms: string
}

export interface PriceContext {
  basePricePerRoomPerNight?: number
  chargeableCurrency?: string | null
  chargeableTotalPrice: number | null
  currency?: string
  displayPricePerRoomPerNight: number | null
  offerId: string
  otherDueAtProperty: number
  resortFeeDueAtProperty: number
  taxesDueAtProperty: number
  taxesPerRoomPerNight?: number
  totalDueAtProperty: number | null
  totalPrice: number | null
  totalUpFront: number | null
  propertyCurrencyCode?: string
}

export enum PerksMismatch {
  FreeBreakfastRemoved = 'free_breakfast_removed',
  NonRefundable = 'became_non_refundable',
  PartiallRefundable = 'became_partially_refundable'
}

export interface BaseOfferContext {
  anchorPrice?: number
  availableRooms?: number
  bookURI: string
  cancellationPenalties?: Offer['cancellationPenalties']
  cancellationPolicy?: {
    freeRefundableUntil: string | null
    description: string | null
  }
  canPayLater: boolean
  cug: string[]
  displayPricePerRoomPerNight: number | null
  hasFreeCancellation: boolean
  isPrivateDeal: boolean
  meals: string[]
  offerId: string
  offerPosition?: number | string | null
  providerCode: string
  providerFeedId?: string | null
  proxyProviderCode: string
  providerRateType?: string
  roomName?: Room['name']
  totalPrice: number | null
  chargeType?: BookingSource[]
  offerToken?: string
  sourceComponent?: string
  /**
   * Expiration time for the Deal Freeze and Charge Later
   */
  paymentExpiryAt?: PaymentExpiryAt
  isSplitBooking?: boolean
  perksMismatch?: PerksMismatch[]
}

export interface CheckoutOfferContext extends BaseOfferContext {
  roomId?: string
  splitBooking?: UnifiedOfferCheckData | null
  bundleId?: string | null
}

interface RateBreakdown {
  baseRate: number
  localTaxes: number
  taxes: number
}

export interface SearchOfferContext extends BaseOfferContext {
  displayedTags: string[]
  displayedTagDiscount?: number
  isAnchorPriceOffer: boolean
  isAppExclusiveRate?: boolean
  isCheapest: boolean
  isCheapestRAA: boolean
  isSharedRoom?: boolean
  isTopOffer: boolean
  offerTags: string[]
  rateBreakdown: RateBreakdown
  numberOfRooms: number
  originalAccessTier?: string
  isStatic?: boolean
  osoOfferPosition?: string | number
  splitBooking?: SapiSplitBookingBundle
  bundleId?: string
  isSmsLocked?: boolean
}

interface PaymentExpiryAt {
  /**
   * Charge date for Charge Later
   */
  chargeExpiryAt?: string
  /**
   * Expiration date for Deal Freeze
   */
  freezeExpiryAt?: string
}

export interface BookingContext {
  /**
   * The status of the booking request, possible values are confirmed, cancelled, error, frozen, freeze_cancelled and reeze_expired
   */
  status: BookingStatus

  /**
   * The cancellation penalties applied to the {@link Offer} booked in case of cancellation
   */
  cancellationPenalties: CancellationPenalty[]
  /**
   * The amount we displayed to the user as the per room per night
   */
  displayPricePerRoomPerNight: number | null
  /**
   * Whether you have or not free cancellation on your booking
   */
  hasFreeCancellation: boolean
  /**
   * The name of the room booked
   */
  roomName: Room['name']
  /**
   * The total amount the user will pay
   */
  totalPrice?: number | null
  /**
   * It's FH's confirmation ID that can be used by FH customer support.
   * In the BoFH API it is called `confirmationId`.
   * We use a different name so it matches the naming on Looker and EvAS
   */
  bookingId: string
  /**
   * The confirmation ID that a provider needs in their customer support
   */
  providerConfirmationId: string
  /**
   * The ID that FH needs in their customer support
   * TODO: [sc-133585] replace that with providerBookingId when BE is ready
   */
  fhBookingId: string
  /**
   * Used to link tracking events to the same booking from the customer perspective
   *
   */
  customerBookingId: string
  /**
   * The entity responsible for handling the user payment
   */
  merchantOfRecord: string

  /**
   * It shows the booking type if it is a Deal Freeze, or Charge Later
   */
  chargeType?: BookingSource[]
  /**
   * Expiration time for Deal Freeze, and Charge Later.
   */
  paymentExpiryAt?: PaymentExpiryAt
  /**
   * The date and time at which the booking was created
   */
  bookedAt?: string
}

export interface ServerErrorContext {
  /**
   * The HTTP status code of the request.
   * https://umbraco.com/knowledge-base/http-status-codes/
   */
  statusCode: number
  /**
   * Which params were passed to the request
   */
  params?: Record<string, unknown>
  /**
   * Which headers were passed to the request
   */
  headers: Record<string, unknown>
  /**
   * Which url was used in the request
   */
  url?: string
  /**
   * Which HTTP method was used for the request
   * https://developer.mozilla.org/en-US/docs/Web/HTTP/Methods
   */
  method?: string
  /**
   * An object containing details about the error that happened
   */
  errorResponse: ErrorResponse | Record<string, unknown>
  /**
   * A descriptive version of the error that happened
   */
  errorMessage: string
  /**
   * Whether the user will be able to keep using the app of the failing of this request means the appplication
   * is rendered useless.
   */
  recoverableError?: boolean
}

export interface NpsContext {
  /**
   * The score given by the user to a NPS survey
   */
  npsScore: number
  /**
   * Represents the question asked to the user after a score is registered. The question usually depends on the score given
   */
  openQuestion?: string
  /**
   * Represents NPS survey scale max value if it's differs from default (11)
   */
  npsScale?: number
  /**
   * Represents the maximum score a user can submit
   */
  npsMaxScore?: number
}

export interface RoomOfferContext {
  roomName: string
  roomMasterId: string
  roomPosition: number | undefined
  roomId: string
  numberOfImages: number
  roomAmenities: string[]
  roomDescription: string
  squashedIds: string[]
  displayPricePerRoomPerNight: number
  isPrivateDeal: boolean
  offerId: string
  totalPrice: number | null
  anchorPrice: number
  offerTags: string[] | undefined
  availableRooms: number
  canPayLater: boolean | undefined
  hasFreeCancellation: boolean
  providerCode: string
  cancellationPenalties: CancellationPenalty[]
  cug: string[]
  providerFeedId: string
  providerRateType?: string
  isClicked: boolean | undefined
  matchType: 'exact' | 'partial' | 'by_terms' | 'by_price' | undefined
  matchedDim?: MatchedDim
  matchedOfferPriceDiff?: number
  services: string[]
  payAtProperty: boolean
  paymentExpiryAt?: PaymentExpiryAt
  chargeType?: BookingSource[]
  offerPosition: number
  proxyProviderCode?: string
  isRoomOffer?: boolean
  offerToken?: string
  // Should be implemented as part of sc-104456
  // isCheapest: boolean
}

// Context that is used for marketing automation purposes (Customer.io)
export interface MarketingAutomationContext {
  hotelName?: string
  hotelImageUrl?: string
  bookingManagementUrl?: string
  paymentUrl?: string
  hotelAddress?: string
  price?: Price
  numberOfNights?: number
  totalGuests?: number
  starRating?: number
  nightlyPrice?: number
}

type ParametrizedEventContexts =
  | ParametrizedHotelContext
  | ParametrizedHotelInfoContext

export interface ParametrizedHotelContext {
  contextType: AnalyticsContext.HotelContext
  hotelId: Hotel['objectID']
}

export interface ParametrizedHotelInfoContext {
  contextType: AnalyticsContext.HotelInfoContext
  hotelId: Hotel['objectID']
}

export interface RoomContext {
  numberOfImages: number
  roomAmenities: string[]
  keyAmenities: string[]
  roomDescription: string
  roomPosition: number
  providerRoomName: string
  displayedRoomName: string
  roomId: string
  roomMasterId?: string
  squashedIds: string[]
  isSelectedRoom: boolean
  offers: RoomOfferContext[]
}

export type RoomContextList = RoomContext[]
