import React, {ReactElement, useContext, useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation} from 'react-router-dom'

import {Box} from '@daedalus/atlas/helpers/Box'
import {useRouter} from '@daedalus/core/src/_web/router/context/RouterContext'
import {
  getIsAuthenticated,
  selectAuthenticationError
} from '@daedalus/core/src/auth/modules/selectors'

import {setEmailSignUpVisibility} from '../../../Email/slice'
import {getHasSusiInUrl} from '../../../hooks/useSUSIBottomSheet'
import {BottomSheet} from './BottomSheet'
import {Agreement, GemsIcon, SignInButtons, SignInTitle} from './components'
import {SusiContext} from './SusiProvider'
import {SusiHistoryState} from './types'

interface Props {
  infoContext: Record<string, unknown>
  IconSlot: ReactElement | null
  TitleSlot: ReactElement | null
  MainContentSlot: ReactElement | null
  BottomContentSlot: ReactElement | null
  isDealFreeze?: boolean
}

export const SusiBottomSheet = ({
  IconSlot,
  TitleSlot,
  MainContentSlot,
  BottomContentSlot,
  isDealFreeze
}: Props) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const location = useLocation<{susi: SusiHistoryState}>()
  const isAuthenticated = useSelector(getIsAuthenticated)
  const isOpen = location.state?.susi?.active && !isAuthenticated
  const {errorMessage, errorType} = useSelector(selectAuthenticationError)
  const hasSusiInUrl = getHasSusiInUrl()
  const {
    handleCloseOverlay,
    handleLogin,
    updateSUSILocationState,
    trackSignInError
  } = useContext(SusiContext)

  const handleEmailLogin = (e: React.SyntheticEvent) => {
    e.preventDefault()
    dispatch(setEmailSignUpVisibility(true))
    handleOnClose()
  }

  const IconComponent = IconSlot ?? <GemsIcon />

  const SignInTitleComponent = TitleSlot ?? <SignInTitle />
  const SignInMainContentComponent = MainContentSlot ?? null
  const SignInBottomContentComponent = BottomContentSlot ?? (
    <>
      <SignInButtons
        handleLogin={handleLogin}
        handleEmailLogin={handleEmailLogin}
      />
      <Agreement />
    </>
  )

  const SignInStep = {
    TitleComponent: <div>{SignInTitleComponent}</div>,
    MainContentComponent: SignInMainContentComponent ? (
      <Box paddingBottom="s600">{SignInMainContentComponent}</Box>
    ) : null,
    BottomContentComponent: <div>{SignInBottomContentComponent}</div>
  }

  const {TitleComponent, MainContentComponent, BottomContentComponent} =
    SignInStep

  useEffect(() => {
    if (errorMessage && errorType) {
      trackSignInError({
        errorMessage,
        errorType
      })
    }
  }, [errorMessage, errorType, trackSignInError])

  const handleOnClose = () => {
    if (hasSusiInUrl) updateSUSILocationState({active: false})
    else router.goBack()
    handleCloseOverlay()
  }

  return (
    <>
      <BottomSheet
        isOpen={isOpen}
        IconComponent={isDealFreeze ? undefined : IconComponent}
        TitleComponent={TitleComponent}
        MainContentComponent={MainContentComponent}
        BottomContentComponent={BottomContentComponent}
        onClose={handleOnClose}
      />
    </>
  )
}
