import {useSelector} from 'react-redux'
import differenceInDays from 'date-fns/differenceInDays'
import parseISO from 'date-fns/parseISO'
import {isVioOfferOrBundleSelected} from 'modules/accommodation/utils'
import {toggle} from 'opticks'
import {curry, isEmpty, isNil, path, pathOr, pick} from 'ramda'
import {createSelector} from 'reselect'
import {RootState} from 'store'
import {SearchParamsType} from 'types/Search'
import {getIsAccommodationPage} from 'utils/getIsAccommodationPage'
import {getSerializedUrlParamsWithoutTempParams} from 'utils/searchParams'
import {
  getIsFromGha,
  getIsFromTripAdvisor,
  getTrafficSource,
  hasDefaultDates
} from 'utils/trafficSource'

import {
  getIsAuthenticated,
  getIsLockedDeal,
  getIsPrivateDeal
} from '@daedalus/core/src/auth/modules/selectors'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {getGsiteValueFromUrl} from '@daedalus/core/src/offer/business/privateDeals'
import {GenericOffer, OfferTagLabel} from '@daedalus/core/src/offer/types/offer'
import {
  SORT_FIELD_POPULARITY,
  SORT_ORDER_ASCENDING
} from '@daedalus/core/src/searchParams/constants'
import {
  dateStringToMiddayDate,
  dateToMiddayDate
} from '@daedalus/core/src/utils/date'
import {parseQueryString} from '@daedalus/core/src/utils/url'

import {
  filterParamNames,
  HOSTEL_PROPERTY_TYPE_ID,
  persistedBaseUrlParamKeys,
  persistedUrlParamKeys,
  persistedUTMParamsKeys,
  PRICE_BUCKET_WIDTH,
  SORT_FIELD_DISTANCE,
  SORT_FIELD_PRICE
} from './constants'
import {GeoDetails, LocationSearchType} from './slice'
import {FilterUrlParams, SearchUrlParams, SortUrlParams} from './types'

type StateType = RootState

type FilterValueType = string | string[] | undefined

const PREFERRED_RATE = 'preferred_rate'

export const getError = createSelector(
  [(state: StateType) => state.search.error],
  error => error
)

/**
 * Gets the current query string from the `search` slice of state
 */
export const getQueryString = (state: StateType): string =>
  state.search.queryString

/**
 * Gets the serialized URL params from the query string with temporary params omitted
 */
export const getUrlParams = createSelector(
  [getQueryString],
  (queryString): SearchUrlParams =>
    getSerializedUrlParamsWithoutTempParams(queryString)
)

export const pluckUrlParams = createSelector(
  [getUrlParams, (_, parameters: string[]) => parameters],
  (urlParams, parameters) =>
    parameters.reduce((acc, parameter) => {
      acc[parameter] = urlParams[parameter]
      return acc
    }, {})
)

const getFilterValue = curry(
  (
    urlParams: FilterUrlParams,
    filterName: string
  ): string | string[] | null | undefined => {
    const filterValue = urlParams[filterName]

    // When user click 0+ guest ratings, the value is 0,  but the filter is not actually active
    if (filterName === 'reviewScore' && filterValue === '0') return

    return filterValue
  }
)

// Note: this relies on the searchParams object only getting recreated when a param actually changed
// https://github.com/reduxjs/reselect#q-why-is-my-selector-recomputing-when-the-input-state-stays-the-same
export const getFiltersFromUrlParams: (
  arg0: StateType
) => Record<string, FilterValueType> = createSelector(
  [getUrlParams],
  urlParams => {
    return filterParamNames.reduce((previous, current) => {
      const filterValue = getFilterValue(urlParams)(current)

      if (filterValue !== undefined) {
        return {
          ...previous,
          [current]: filterValue
        }
      }

      return previous
    }, {})
  }
)

/**
 * Get the keys of the currently active filters
 */
export const getActiveFiltersKeys = createSelector(
  [getUrlParams, (_, filterParameterNames: string[]) => filterParameterNames],
  (urlParams, filterParameterNames) => {
    const excludeList = new Set(['freeCancellation', 'chainIds'])

    return filterParameterNames.reduce((acc, current) => {
      const filterValue = getFilterValue(urlParams)(current)

      if (isNil(filterValue) || excludeList.has(current)) {
        return acc
      }

      return [...acc, current]
    }, [])
  }
)

/**
 * Get the `sortField` and `sortOrder` parameters from the URL
 */
export const getSortParams = createSelector(
  [getUrlParams],
  (urlParams): SortUrlParams => {
    const {userLocationSearch} = urlParams
    const isUserLocationSearch = userLocationSearch === '1'

    const sortField =
      urlParams?.sortField ||
      (isUserLocationSearch ? SORT_FIELD_DISTANCE : SORT_FIELD_POPULARITY)
    const sortOrder = urlParams?.sortOrder || SORT_ORDER_ASCENDING

    return {
      sortField,
      sortOrder
    }
  }
)

export const isSortAscendingByPrice = createSelector(
  [getSortParams],
  (sortParams): boolean => {
    const {sortField, sortOrder} = sortParams

    return sortField === SORT_FIELD_PRICE && sortOrder === SORT_ORDER_ASCENDING
  }
)

export const getIsSortByDistance = createSelector(
  [getSortParams],
  (sortParams): boolean => {
    const {sortField} = sortParams

    return sortField === SORT_FIELD_DISTANCE
  }
)

export const getListOfValues = (state: StateType) => state.search.listOfValues

export const getSearchDisplayName = (state: StateType): string => {
  return pathOr('', ['search', 'searchData', 'searchDisplayName'], state)
}

export const getPlaceCategory = (
  state: StateType
): number | null | undefined => {
  return path(['search', 'searchData', 'placeCategory'], state)
}

export const getGeoDetails = (state: StateType): GeoDetails =>
  path(['search', 'searchData', 'geoDetails'], state)

export const getPriceBucketWidth = (state: StateType): number => {
  return pathOr(
    PRICE_BUCKET_WIDTH,
    ['search', 'searchData', 'priceBucketWidth'],
    state
  )
}

const getLandingValue = (state: StateType): string =>
  state.search.landingQueryString

/**
 * Gets the original landing query string saved in the state
 */
export const getLandingQueryString = createSelector(
  [getLandingValue],
  landingQueryString => landingQueryString
)

/**
 * Gets the serialized version of the landing query string saved in the state
 */
export const getSerializedLandingQuery = createSelector(
  [getLandingQueryString],
  (landingQueryString): Record<string, string> =>
    parseQueryString(landingQueryString) as Record<string, string>
)

/**
 * Gets the landing value for profileId
 */
export const getLandingProfileId = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): string => serializedLandingQuery.profile
)

/**
 * Gets the landing label param from the original landing query saved in the state
 */
export const getLandingLabel = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): string => {
    const {label} = serializedLandingQuery
    return label || ''
  }
)

/**
 * Gets the landing checkIn param from the original landing query saved in the state
 */
export const getLandingCheckIn = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): string => {
    const {checkIn} = serializedLandingQuery
    return checkIn || ''
  }
)

/**
 * Gets the landing checkInDistance param from the original landing query saved in the state
 */
export const getLandingCheckInDistance = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): string => {
    const {checkInDistance} = serializedLandingQuery
    return checkInDistance || ''
  }
)

/**
 * Gets the traffic source from the original landing query saved in the state
 */
export const getLandingTrafficSource = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): string => {
    const {label, utm_source: utmSource} = serializedLandingQuery
    return getTrafficSource(utmSource, label)
  }
)

/**
 * Get the `hotelId` parameter from the URL
 */
export const getHotelId = createSelector(
  [getUrlParams],
  (urlParams): string | null | undefined => urlParams.hotelId
)

/**
 * Get the `placeId` parameter from the URL
 */
export const getPlaceId = createSelector(
  [getUrlParams],
  (urlParams): string | null | undefined => urlParams.placeId
)

/**
 * Get the `hotelName` parameter from the URL
 */
export const getHotelName = createSelector(
  [getUrlParams],
  (urlParams): string | null | undefined => urlParams.hotelName
)

/**
 * Get the `noHostels` parameter from the URL
 */
export const getNoHostels = createSelector(
  [getUrlParams],
  (urlParams): BooleanUrlParam | null | undefined => urlParams.noHostels
)

/**
 * Get the `getClickedOfferRate` parameter from the URL
 *
 * Add as part of 366b54ed-price-difference-popup
 */
export const getClickedOfferRate = createSelector(
  [getUrlParams],
  (urlParams): number => Number(urlParams.clickedOfferRate)
)

/**
 * Get whether the `noHostels` filter is selected
 */
export const getIsHostelsFilterSelected = createSelector(
  [getUrlParams],
  (urlParams): boolean =>
    urlParams.propertyTypes?.includes(HOSTEL_PROPERTY_TYPE_ID)
)

/**
 * Get the `isUserSearch` value from the `search` slice of state
 */
export const getIsUserSearch = createSelector(
  [(state: StateType) => state.search.isUserSearch],
  isUserSearch => isUserSearch || false
)

/**
 * Get the `isDefaultDatesSearch` value from the `search` slice of state
 */
export const getIsDefaultDatesSearch = (state: StateType): boolean =>
  state.search.isDefaultDatesSearch || false

/**
 * Get the `isLandingExperience` value from the `search` slice of state
 */
export const getIsLandingExperience = (state: StateType): boolean =>
  state.search.isLandingExperience || false

/**
 * Get the `isHomePageLandingExperience` value from the `search` slice of state
 */
export const getIsHomePageLandingExperience = (state: StateType): boolean =>
  state.search.isHomePageLandingExperience || false

/**
 * Get the `isUserChangedDates` value from the `search` slice of state
 */
export const getIsUserChangedDates = (state: StateType): boolean =>
  state.search.isUserChangedDates || false

/**
 * Get the `isNoDatesSearch` value from the `search` slice of state
 */
export const geIsNoDatesSearch = (state: StateType): boolean =>
  state.search.isNoDatesSearch || false

/**
 * Get the `isHomeSearch` value from the `search` slice of state
 */
export const getIsHomeSearch = (state: StateType): boolean =>
  state.search.isHomeSearch || false

/**
 * Get whether the search is a user geolocation search
 */
export const getIsUserLocationSearch = createSelector(
  (state: StateType): boolean => state.search.isUserLocationSearch,
  isUserLocationSearch => isUserLocationSearch || false
)

/**
 * Get the origin of the user location coordinates
 */
export const getUserLocationSearchType = (state: StateType) =>
  state.search.userLocationSearchType

export const getIsUserOrUrlLocationSearch = createSelector(
  getUserLocationSearchType,
  userLocationSearch =>
    [LocationSearchType.UserLocation, LocationSearchType.UrlLocation].includes(
      userLocationSearch
    )
)

/**
 * Get the `isFsmr` (force search multi-room) value from the `search` slice of state
 */
export const getIsFsmr = (state: StateType): boolean =>
  state.search.isFsmr || false

export const getIsOptimizeRooms = (state: StateType): boolean => {
  if (getIsAccommodationPage() && isVioOfferOrBundleSelected()) return false
  return state.search.isOptimizeRooms || false
}

/**
 * Get whether the search should be treated as a multi-room bundles search.
 * Based on whether fsmr or optimizeRooms is active.
 */
export const getIsMultiRoomBundlesSearch = createSelector(
  [getIsFsmr, getIsOptimizeRooms],
  (isFsmr, isOptimizeRooms): boolean => Boolean(isFsmr || isOptimizeRooms)
)

/**
 * Get the `isMapOpen` value from the `search` slice of state
 */
export const getIsMapOpen = (state: StateType): boolean =>
  state.search.isMapOpen || false

/**
 * Get the `activeHotelId` value from the `search` slice of state
 */
export const getActiveHotelId = (state: StateType): string =>
  state.search.activeHotelId

/**
 * Get whether the user landed with a check-in date or check-in distance
 */
export const getHasLandingDates = createSelector(
  [getLandingCheckInDistance, getLandingCheckIn],
  (landingCheckInDistance, landingCheckIn): boolean => {
    return Boolean(landingCheckIn || landingCheckInDistance)
  }
)

/**
 * Get whether the user landed on the search results page with no dates
 */
export const getIsNoDatesLandingTraffic = createSelector(
  [getHasLandingDates, getIsHomePageLandingExperience],
  (hasLandingDates, isHomePageLandingExperience): boolean => {
    return Boolean(!hasLandingDates && !isHomePageLandingExperience)
  }
)

/**
 * Get the `layout` value from the `search` slice of state
 * can return undefined as this is the initial value before
 * being set in the state from the URL params
 */
export const getLayout = createSelector(
  [(state: StateType) => state.search.layout],
  layout => layout
)

/**
 * Get the `searchDesktopHeaderHeight` value from the `search` slice of state
 */
export const getSearchDesktopHeaderHeight = (state: StateType): number =>
  state.search.searchDesktopHeaderHeight

/**
 * Get the `isTopHeaderHidden` value from the `search` slice of state
 */
export const getIsTopHeaderHidden = (state: StateType): boolean =>
  state.search.isTopHeaderHidden

const getHasDefaultLandingDates: (state: StateType) => boolean = createSelector(
  [getLandingLabel],
  label => hasDefaultDates({label})
)

export const isDatesDefault = (state: StateType): boolean =>
  !getHasLandingDates(state) || getHasDefaultLandingDates(state)

export const isLandingCheckInToday = createSelector(
  [getLandingCheckInDistance, getLandingCheckIn],
  (landingCheckInDistance, landingCheckIn): boolean => {
    if (landingCheckInDistance) {
      return Boolean(landingCheckInDistance === '0')
    }

    const today = dateToMiddayDate(new Date())
    return Boolean(differenceInDays(parseISO(landingCheckIn), today) === 0)
  }
)

export const getIsHotelDetailsOpenOnLanding = createSelector(
  [getSerializedLandingQuery],
  (serializedLandingQuery): boolean =>
    serializedLandingQuery.openHotelDetails === '1'
)

export const getIsSearchBoxOpenByDefault = createSelector(
  [getIsLandingExperience, isDatesDefault, isLandingCheckInToday],
  (isLandingExperience, datesIsDefault, landingCheckInIsToday) => {
    if (isLandingExperience && datesIsDefault && !landingCheckInIsToday) {
      return toggle('ab519a8e-date-confirmation-tooltip', true, false)
    }

    return false
  }
)

export const getFreeCancellation = createSelector(
  [getUrlParams],
  (urlParams): boolean => Boolean(Number(urlParams.freeCancellation))
)

const getCheckIn = (state: StateType) =>
  state.sapiSearch.searchParameters?.checkIn

const getCheckInDayDistance = createSelector([getCheckIn], checkIn => {
  const todayUtc = dateToMiddayDate(new Date())
  const checkInUtc = dateStringToMiddayDate(checkIn)
  return differenceInDays(checkInUtc, todayUtc)
})

export const getShowFreeCancellationFilter = createSelector(
  [getCheckInDayDistance],
  checkInDayDistance => checkInDayDistance >= 2
)

/**
 * Get the `Gsite` parameter from the URL
 */
export const getGsiteParam = createSelector(
  [getUrlParams],
  (urlParams): string | undefined => {
    return getGsiteValueFromUrl(urlParams as SearchParamsType)
  }
)

/**
 * Get the `clickId` parameter from the URL
 * This is a parameter passed from TripAdvisor
 */
export const getClickId = createSelector(
  [getUrlParams],
  (urlParams): string | undefined => urlParams.clickId
)

/**
 * Get if the current hotel is visited
 * @param hotelId - The hotel id
 * @returns A boolean of if the hotel has been visited
 */
export const getIsHotelVisited =
  (hotelId: string) =>
  (state: StateType): boolean =>
    state.search.visitedHotels[hotelId] ?? false

export const getSelectedAvailabilityDate = (state: StateType) =>
  state.search.selectedAvailabilityDate

/**
 * Get the url params that should be persisted
 */
export const getPersistedUrlParams = createSelector([getUrlParams], urlParams =>
  pick(persistedUrlParamKeys, urlParams)
)

/**
 * Get the base url params that should be persisted
 */
export const getPersistedBaseUrlParams = createSelector(
  [getUrlParams],
  urlParams => pick(persistedBaseUrlParamKeys, urlParams)
)

/**
 * Get the utm params that should be persisted
 */
export const getPersistedUTMParams = createSelector([getUrlParams], urlParams =>
  pick(persistedUTMParamsKeys, urlParams)
)

/**
 *  Check the `profile` url param to see if we are in OTA mode
 */
export const getIsOTAMode = createSelector(
  [getUrlParams],
  urlParams => urlParams.profile === 'naiogwg0jg'
)

/**
 * Gets user landed directly on SRP or not
 */
export const getIsUserLandingDirectlyOnSrp = createSelector(
  [getSerializedLandingQuery],
  (landingQueryString): boolean => {
    if (isEmpty(landingQueryString)) return false
    // Added according to avoid targeting traffic directly on AP from meta users
    if (landingQueryString['showHotelDetails']) return false
    return Boolean(
      landingQueryString['hotelId'] ?? landingQueryString['placeId']
    )
  }
)

/**
 * Returns the the esd value from the url
 */
export const getEsdUrlParam = createSelector(
  [getUrlParams],
  (urlParams: SearchUrlParams): string | undefined => urlParams.esd || undefined
)

/**
 * Returns the the epv value from the url
 */
export const getEpvUrlParam = createSelector(
  [getUrlParams],
  (urlParams: SearchUrlParams): string | undefined => urlParams.epv || undefined
)

/**
 * Returns the mlos (min length of stay) value from the url
 */
export const getMlos = createSelector(
  [getUrlParams],
  (urlParams): number | undefined => Number(urlParams.mlos) || undefined
)

/**
 * Returns whether the `Your choice` tag should be shown.
 * It should not be shown if the traffic is comes from TripAdvisor and the offer is private and the user is not authenticated.
 * This logic was introduced based on this discussion: https://viodotcom.slack.com/archives/CQ5HPT3L2/p1712821942795109
 * @param offer - Offer object
 * @returns - A boolean indicating if the tag should be shown
 */
export const getShouldShowYourChoiceTag =
  (offer?: GenericOffer | null, isSplitBooking = false) =>
  (state: RootState) => {
    const isTA = getIsFromTripAdvisor(state)
    const isPrivateDeal = getIsPrivateDeal(offer)(state)
    const isAuthenticated = getIsAuthenticated(state)

    if (isTA && isPrivateDeal && !isAuthenticated && !isSplitBooking) {
      return false
    }

    return offer?.tags?.includes(PREFERRED_RATE)
  }

/**
 * Returns the `freeTextSearchString` value from the `search` slice of state
 * @returns The `freeTextSearchString` value from the `search` slice of state
 */
export const getFreeTextSearchString = (state: StateType) =>
  state.search.freeTextSearchString

/**
 * Returns the the `profile` value from the url
 */
export const getProfileUrlParam = createSelector(
  [getUrlParams],
  (urlParams: SearchUrlParams): string => urlParams.profile ?? null
)

/**
 * Returns the map zoom level
 * Which is kept in sync with the map zoom level in MapBox to preserve even when the map
 * is hidden or unmounted
 */
export const getMapZoomLevel = (state: StateType) => state.search.mapZoomLevel

/**
 * An integer we can increment every time we want to explicitly force a search when nothing else changes
 * WARNING: this is only meant for sapi-preheat, do not abuse for other features
 */
export const getForceSearch = (state: StateType) => state.search.forceSearch

// added as part of feature-unlock-gha-preferred-rate-offer
// https://viodotcom.slack.com/archives/C06H33TDR6K/p1743672083287479
export const getIsLockedDealExcludingGHA =
  (offer?: GenericOffer | null) => (state: RootState) => {
    const isGHA = useSelector(getIsFromGha)
    const isYourChoice = hasOfferTag(offer, OfferTagLabel.IsYourChoice)

    if (isGHA && isYourChoice) {
      return toggle(
        'feature-unlock-gha-preferred-rate-offer',
        getIsLockedDeal(offer)(state),
        false
      )
    }

    return getIsLockedDeal(offer)(state)
  }
