import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'

import {getUserSessionService} from '@daedalus/core/src/auth/services'

import {CreatePriceWatch, PriceWatchMetric, PriceWatchPayload} from '../types'

const ENV =
  location.origin.includes('tst.') ||
  location.origin.includes('fih.io') ||
  location.origin.includes('localhost')
    ? 'dev'
    : 'prod'
const PRICE_WATCH_API_URL = `https://api.${ENV}-membership.vio.com/price-watch`

const HEADERS = {
  'Content-Type': 'application/json'
}

export const priceWatchApi = createApi({
  reducerPath: 'priceWatchApi',
  baseQuery: fetchBaseQuery({baseUrl: PRICE_WATCH_API_URL}),
  tagTypes: ['PriceWatchTrackings'],
  endpoints: builder => ({
    cancelPriceWatch: builder.mutation<
      string,
      {priceWatchId: string; userId: string; remove?: boolean}
    >({
      query: ({priceWatchId, userId, remove = false}) => ({
        url: '/cancel',
        method: 'POST',
        headers: HEADERS,
        body: {priceWatchId, userId, remove}
      }),
      async onQueryStarted(
        {priceWatchId, userId, remove},
        {dispatch, queryFulfilled}
      ) {
        const patchResult = dispatch(
          priceWatchApi.util.updateQueryData(
            'getUserPriceWatchTrackings',
            {userId},
            draft => {
              if (remove)
                return draft.filter(
                  tracking => tracking.priceWatchId !== priceWatchId
                )

              const tracking = draft.find(t => t.priceWatchId === priceWatchId)
              if (tracking) tracking.metric = PriceWatchMetric.Paused
              return draft
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      }
    }),
    getUserPriceWatchTrackings: builder.query<
      PriceWatchPayload[],
      {userId: string}
    >({
      query: ({userId}) => ({
        url: '/trackings',
        method: 'GET',
        params: {userId}
      }),
      providesTags: ['PriceWatchTrackings']
    }),
    createAnonymousPriceWatchTracking: builder.mutation<
      PriceWatchPayload,
      CreatePriceWatch
    >({
      query: body => ({
        url: '/create',
        method: 'POST',
        headers: HEADERS,
        body
      }),
      async onQueryStarted(newTracking, {dispatch}) {
        dispatch(
          priceWatchApi.util.updateQueryData(
            'getUserPriceWatchTrackings',
            {userId: newTracking.userId},
            draft => {
              const existingEntry = draft.find(
                t =>
                  t.hotelId === newTracking.hotelId &&
                  t.checkIn === newTracking.checkIn &&
                  t.nights === newTracking.nights &&
                  t.currency === newTracking.currency &&
                  t.rooms === newTracking.rooms
              )

              if (existingEntry) {
                existingEntry.metric = PriceWatchMetric.Resumed
                return draft
              }

              const optimisticEntry = {
                ...newTracking,
                metric: PriceWatchMetric.Created
              }
              draft.push(optimisticEntry as unknown as PriceWatchPayload)
            }
          )
        )
      }
    }),
    mergePriceWatchTrackings: builder.mutation<
      PriceWatchPayload[],
      {anonymousId: string}
    >({
      async queryFn(arg, _queryApi, _extraOptions, fetchWithBQ) {
        const {accessToken} = await getUserSessionService()
        const result = await fetchWithBQ({
          url: '/merge',
          method: 'POST',
          headers: {
            ...HEADERS,
            Authorization: `Bearer ${accessToken}`
          },
          body: {anonymousId: arg.anonymousId}
        })
        return {data: result.data as PriceWatchPayload[]}
      }
    })
  })
})

export const {
  useCancelPriceWatchMutation,
  useGetUserPriceWatchTrackingsQuery,
  useCreateAnonymousPriceWatchTrackingMutation,
  useMergePriceWatchTrackingsMutation
} = priceWatchApi
