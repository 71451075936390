import React from 'react'
import {useIntl} from 'react-intl'

import {InputSelect} from '@daedalus/atlas/InputSelect'

import {sortLabels, sortList} from './ReviewSorting'

interface Props {
  onSortChange: (value: string) => void
  onSortClick: () => void
}

// Added as part of 9633c72c-guest-reviews-sort-filter experiment
export const ReviewSortingDesktop: React.FC<Props> = ({
  onSortChange,
  onSortClick
}) => {
  const {formatMessage} = useIntl()

  return (
    <div>
      <InputSelect
        size="sm"
        onChange={onSortChange}
        ariaLabel={formatMessage(sortLabels.mostRelevant)}
        onClick={onSortClick}
      >
        {sortList.map(item => (
          <option key={item.key} value={item.value}>
            {formatMessage(sortLabels[item.key])}
          </option>
        ))}
      </InputSelect>
    </div>
  )
}
