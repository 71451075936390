import React, {useCallback, useEffect, useRef, useState} from 'react'
import {defineMessages, useIntl} from 'react-intl'

import {Box} from '@daedalus/atlas/helpers/Box'
import {InputTextType} from '@daedalus/atlas/helpers/InputBase'
import {Icon} from '@daedalus/atlas/Icon'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import useDebounce from '@daedalus/core/src/utils/debounce/hooks/useDebounce'

import {SearchFieldMobile} from './styles'

interface PropsType {
  displayValue?: string
  onSuggestionsRequested: (arg0: string, arg1: boolean) => void
  focusOnMount?: boolean
  shouldLoadUserSearchHistory?: boolean
  hasError?: boolean
  errorMessage?: string
}

const destinationPickerMessages = defineMessages({
  destinationOverlayPlaceholder: {
    id: 'whereAreYouGoing',
    defaultMessage: 'Where are you going?'
  }
})

export const DestinationPicker: React.FC<PropsType> = ({
  displayValue = '',
  onSuggestionsRequested,
  focusOnMount = false,
  shouldLoadUserSearchHistory = false
}) => {
  const {formatMessage} = useIntl()
  const inputFieldRef = useRef<null | HTMLInputElement>(null)
  const [inputValue, setInputValue] = useState(displayValue)
  const debouncedInputValue: string = useDebounce(inputValue, 250)
  const {brandHasAlternativeSearchForm} = useBrand()

  useEffect(() => {
    if (focusOnMount) inputFieldRef.current?.focus?.()
  }, [focusOnMount])

  useEffect(() => {
    setInputValue(displayValue)
  }, [setInputValue, displayValue])

  // Requesting suggestions can cause a loop when we rely on dep stability so check that value is actually changed
  const previousDebouncedInputValue = useRef<string>(debouncedInputValue)

  useEffect(() => {
    const isChanged =
      debouncedInputValue !== previousDebouncedInputValue.current
    if (!isChanged) return

    previousDebouncedInputValue.current = debouncedInputValue

    if (debouncedInputValue) {
      onSuggestionsRequested(debouncedInputValue, false)
    } else {
      onSuggestionsRequested('', true)
    }
  }, [debouncedInputValue, onSuggestionsRequested])

  const handleInputChange = useCallback(
    (value: string) => {
      setInputValue(value)
    },
    [setInputValue]
  )

  const handleInputFocus = useCallback(
    ({target}: React.ChangeEvent<HTMLInputElement>) => {
      const {value} = target
      // If the input has a value, request suggestions based on that value
      // If the input is empty, load user search history instead
      const loadUserHistory = value === '' || shouldLoadUserSearchHistory
      onSuggestionsRequested(value, loadUserHistory)
    },
    [shouldLoadUserSearchHistory, onSuggestionsRequested]
  )

  const handleClearDestination = useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault()

      setInputValue('')
      inputFieldRef.current?.focus()
    },
    [inputFieldRef, setInputValue]
  )

  return (
    <Box paddingLeft="s300" paddingRight="s400">
      <SearchFieldMobile
        ref={inputFieldRef}
        dataId="DestinationInput"
        id="DestinationInput"
        name="DestinationInput"
        closeButtonDataId="DestinationInputClear"
        customProps={{
          role: 'presentation',
          autoCapitalize: 'off',
          autoCorrect: 'off',
          spellCheck: 'false',
          onFocus: handleInputFocus
        }}
        icon={
          <Icon name="Pin" size="md" colorPath="content.interactive.c800" />
        }
        isRounded={!brandHasAlternativeSearchForm}
        placeholder={formatMessage(
          destinationPickerMessages.destinationOverlayPlaceholder
        )}
        size="lg"
        type={InputTextType.search}
        value={inputValue}
        onChange={handleInputChange}
        onClear={handleClearDestination}
      />
    </Box>
  )
}
