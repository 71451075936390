import {once} from 'ramda'
import {Dispatch} from 'redux'

import {logTimeEnd} from '../../_web/utils/logging/performanceTimer'
import {trackEvent} from '../modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Page,
  PerformanceMetric,
  Team,
  TrackEventPayload
} from '../types/Events'

interface TimingEventPayloadParams {
  metric: PerformanceMetric
  entity: Entity
  team: Team
  duration: number
  page: Page
}

/**
 * Get the payload for a timing event
 * @param params - The parameters object
 * @param params.metric - The metric to track
 * @param params.entity - The entity to track
 * @param params.team - The team to track
 * @param params.duration - The duration of the event
 * @param params.page - The page to track
 */
export const getTrackTimingEventPayload = ({
  metric,
  entity,
  team,
  duration,
  page
}: TimingEventPayloadParams): Omit<TrackEventPayload, 'payload'> => ({
  category: Category.System,
  entity,
  page,
  action: Action.PerformanceMeasured,
  analyticsContext: {
    [AnalyticsContext.PerformanceMeasuredContext]: {
      metric,
      value: duration,
      unit: 'ms'
    }
  },
  team
})

interface TimingEventParams {
  dispatch: Dispatch
  metric: PerformanceMetric
  measureStartName: string
  entity: Entity
  page: Page
  team?: Team
  payload?: TrackEventPayload['payload']
}

/*
 * Tracks a timing event for performance measurement
 * @param dispatch - Redux dispatch function
 * @param metric - Metric to measure from PerformanceMetric enum
 * @param entity - Entity associated with the timing event (default: Entity.PaymentPage)
 * @param team - Team associated with the timing event (default: Team.Default)
 * @param payload - Additional payload to include with the timing event
 * @returns void
 */
const trackTimingEvent = ({
  dispatch,
  metric,
  measureStartName,
  entity,
  page,
  team = Team.Default,
  payload
}: TimingEventParams): void => {
  try {
    const metricName = `${entity}-${metric}`
    const duration = logTimeEnd(metricName, measureStartName, false)
    if (!duration) return

    const event = getTrackTimingEventPayload({
      metric,
      entity,
      team,
      duration,
      page
    })
    dispatch(trackEvent({...event, payload}))
  } catch (error) {
    console.error(`Error tracking timing event for ${metric}:`, error)
  }
}

const trackTimingEventOnce = ({
  metric,
  measureStartName,
  entity,
  page,
  team = Team.Default,
  payload
}: Omit<TimingEventParams, 'dispatch'>) =>
  once((dispatch: Dispatch): void => {
    trackTimingEvent({
      dispatch,
      metric,
      measureStartName,
      entity,
      page,
      team,
      payload
    })
  })

/**
 * Tracks when the payment page JS loaded and skeleton is shown to start making API calls
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackPaymentPageFRP = trackTimingEventOnce({
  metric: PerformanceMetric.FRP,
  measureStartName: 'responseStart',
  entity: Entity.PaymentPage,
  page: Page.Payment
})

/**
 * Tracks when Payment Page offer is loaded
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackPaymentPageOfferMRP = trackTimingEventOnce({
  metric: PerformanceMetric.MRP,
  measureStartName: 'responseStart',
  entity: Entity.Offer,
  page: Page.Payment
})

/**
 * Tracks when the payment methods are loaded on the payment page
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackPaymentPagePaymentMethodsMRP = trackTimingEventOnce({
  metric: PerformanceMetric.FRP,
  measureStartName: 'responseStart',
  entity: Entity.PaymentMethods,
  page: Page.Payment
})

/**
 * Tracks when the Accommodation page loaded and skeleton is shown to start making API calls
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackAccommodationPageFRP = trackTimingEventOnce({
  metric: PerformanceMetric.FRP,
  measureStartName: 'responseStart',
  entity: Entity.AccommodationPage,
  page: Page.Accommodation
})

/**
 * Tracks when Accommodation page selected deal card is shown
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackAccommodationPageSelectedDealMRP = trackTimingEventOnce({
  metric: PerformanceMetric.MRP,
  measureStartName: 'responseStart',
  entity: Entity.SelectedDeal,
  page: Page.Accommodation
})

/**
 * Tracks when Homepage is shown
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackHomePageMRP = trackTimingEventOnce({
  metric: PerformanceMetric.MRP,
  measureStartName: 'responseStart',
  entity: Entity.HomePage,
  page: Page.Home
})

/**
 * Tracks when SRP page has at least one offer
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackSearchResultsPageMRP = trackTimingEventOnce({
  metric: PerformanceMetric.MRP,
  measureStartName: 'responseStart',
  entity: Entity.Offer,
  page: Page.SearchResults
})

/**
 * Tracks when SRP page skeleton is shown
 * @param dispatch - Redux dispatch function
 * @returns void
 */
export const trackSearchResultsPageFRP = trackTimingEventOnce({
  metric: PerformanceMetric.FRP,
  measureStartName: 'responseStart',
  entity: Entity.Skeleton,
  page: Page.SearchResults
})
