import {IntlFormatters, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getShouldShowYourChoiceTag} from 'modules/search/selectors'
import {toggle} from 'opticks'

import {
  getCancellationDeadline,
  getHasFreeCancellation
} from '@daedalus/core/src/offer/business/cancellationPenalties'
import {hasChargeLaterTag} from '@daedalus/core/src/offer/business/chargeLater'
import {roomLabels} from '@daedalus/core/src/offer/business/getRoomLabels'
import {
  getMealsAmenities,
  getPrioritizedMeals
} from '@daedalus/core/src/offer/business/meal'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'

interface UseGetRoomLabelsProps {
  offer: SearchOffer
  labels?: Record<string, unknown>
  shouldShowFreeCancellationDate?: boolean
  isSplitBooking?: boolean
}

interface GetRoomLabelsProps extends UseGetRoomLabelsProps {
  formatMessage: IntlFormatters['formatMessage']
  formatDate: IntlFormatters['formatDate']
  shouldShowYourChoice: boolean
}

export const getRoomLabels = ({
  offer,
  labels = {},
  shouldShowFreeCancellationDate = false,
  formatMessage,
  formatDate,
  shouldShowYourChoice,
  isSplitBooking
}: GetRoomLabelsProps): string[] => {
  const isChargeLaterEligible = !isSplitBooking && hasChargeLaterTag(offer)

  const canPayLater = offer?.package?.canPayLater

  const hasFreeCancellation = getHasFreeCancellation(
    offer?.cancellationPenalties
  )
  const meals = getMealsAmenities(offer)

  const roomLabelItems = {
    freeCancellation: hasFreeCancellation,
    payLater: canPayLater,
    ...labels
  }
  const translatedRoomLabels = Object.keys(roomLabelItems).reduce(
    (out, key) => {
      if (roomLabelItems[key] && roomLabels[key]) {
        if (key === 'freeCancellation' && shouldShowFreeCancellationDate) {
          const freeCancellationMessage = formatMessage(
            roomLabels['freeCancellation']
          )
          const freeCancellationDeadline = getCancellationDeadline(
            offer.cancellationPenalties
          )
          const freeCancellationDeadlineMessage = formatMessage(
            {id: 'beforeDeadline', defaultMessage: 'before {date}'},
            {
              date: formatDate(freeCancellationDeadline, {
                day: '2-digit',
                month: 'short',
                timeZone: 'UTC'
              })
            }
          )

          return out.concat(
            `${freeCancellationMessage} ${freeCancellationDeadlineMessage}`
          )
        }

        return out.concat(formatMessage(roomLabels[key]))
      }

      return out
    },
    []
  )

  const translatedMealsLabels = getPrioritizedMeals(meals).map(
    meal => meal && roomLabels[meal] && formatMessage(roomLabels[meal])
  )

  const translatedYourChoiceLabel = shouldShowYourChoice
    ? [formatMessage(roomLabels.yourChoice)]
    : []

  const bookNowPayLaterMessage = toggle(
    '50feb427-cl-differential-price',
    roomLabels.bookNowPayLater,
    roomLabels.chargeLater
  )

  const translatedChargeLaterLabel = isChargeLaterEligible
    ? [formatMessage(bookNowPayLaterMessage)]
    : []

  return [
    ...translatedYourChoiceLabel,
    ...translatedRoomLabels,
    ...translatedChargeLaterLabel,
    ...translatedMealsLabels
  ]
}

export const useGetRoomLabels = ({
  offer,
  labels = {},
  shouldShowFreeCancellationDate = false,
  isSplitBooking = false
}: UseGetRoomLabelsProps): string[] => {
  const {formatMessage, formatDate} = useIntl()
  const shouldShowYourChoice = useSelector(
    getShouldShowYourChoiceTag(offer, isSplitBooking)
  )

  return getRoomLabels({
    offer,
    labels,
    shouldShowFreeCancellationDate,
    isSplitBooking,
    formatMessage,
    formatDate,
    shouldShowYourChoice
  })
}
