import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {equals} from 'ramda'

import {setEmailSignUpVisibility} from './slice'
import {getIsEmailSignInOpen} from './slice/selectors'

export interface FormData {
  emailAddress: string
}

type EmailSignUpContextType = {
  isOpen: boolean
  onClose: () => void
  isSecondStep: boolean
  emailAddress: string
  setIsSecondStep: (value: boolean) => void
  handleValidateOneTimePassword: (oneTimePassword: string) => void
  oneTimePasswordError: boolean
  setOneTimePasswordError: (value: boolean) => void
  setEmailAddress: (value: string) => void
  requestOneTimePassword: () => void
  shouldEnableSend: boolean
  isSubmitting: boolean
  isValidating: boolean
  shouldCountTime: boolean
  secondsRemaining: number
  hasSubmitError: boolean
  setUnSubmittedEmailAddress: (value: string) => void
  handleSubmitEmailAddress: (formData: FormData) => void
}

type Props = {
  children: ReactNode
}

export const RESEND_WAITING_TIME = 30

const EmailSignUpContext = createContext<EmailSignUpContextType | undefined>(
  undefined
)

export const useEmailSignUpContext = (): EmailSignUpContextType => {
  const context = useContext(EmailSignUpContext)
  if (!context) {
    throw new Error(
      'useEmailSignUpContext must be used within an EmailSignUpProvider'
    )
  }
  return context
}

export const EmailSignUpProvider = ({children}: Props) => {
  const dispatch = useDispatch()
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null)

  const isOpen = useSelector(getIsEmailSignInOpen)
  const [hasSubmitError, setHasSubmitError] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [oneTimePasswordError, setOneTimePasswordError] = useState(false)
  const [emailAddress, setEmailAddress] = useState('')
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [shouldCountTime, setShouldCountTime] = useState(false)
  const [secondsRemaining, setSecondsRemaining] = useState(RESEND_WAITING_TIME)
  const [shouldEnableSend, setShouldEnableSend] = useState(true)
  const [unSubmittedEmailAddress, setUnSubmittedEmailAddress] = useState('')

  const requestOneTimePassword = async () => {
    try {
      setIsSubmitting(true)
      setOneTimePasswordError(false)
      setShouldCountTime(true)
      setIsSecondStep(true)
      setHasSubmitError(false)
    } catch (error: unknown) {
      setHasSubmitError(true)
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleSubmitEmailAddress = (formData: FormData) => {
    setEmailAddress(formData.emailAddress)
    requestOneTimePassword()
  }

  const handleValidateOneTimePassword = async () => {
    setIsValidating(true)
    setOneTimePasswordError(true)
    setIsValidating(false)
  }

  const onClose = () => {
    dispatch(setEmailSignUpVisibility(false))
    setIsSecondStep(false)
    setHasSubmitError(false)
  }

  const resetWaitingTime = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    intervalRef.current = null
    setShouldEnableSend(true)
    setShouldCountTime(false)
    setSecondsRemaining(RESEND_WAITING_TIME)
  }

  useEffect(() => {
    if (!shouldCountTime) return

    setShouldEnableSend(false)

    intervalRef.current = setInterval(() => {
      setSecondsRemaining(prev => prev - 1)
    }, 1000)

    if (equals(secondsRemaining, 0)) {
      resetWaitingTime()
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [secondsRemaining, shouldCountTime])

  useEffect(() => {
    if (emailAddress !== unSubmittedEmailAddress) {
      resetWaitingTime()
    }
  }, [emailAddress, unSubmittedEmailAddress])

  return (
    <EmailSignUpContext.Provider
      value={{
        isOpen,
        onClose,
        isSecondStep,
        emailAddress,
        setIsSecondStep,
        oneTimePasswordError,
        setOneTimePasswordError,
        setEmailAddress,
        requestOneTimePassword,
        shouldEnableSend,
        isSubmitting,
        isValidating,
        shouldCountTime,
        secondsRemaining,
        hasSubmitError,
        handleValidateOneTimePassword,
        setUnSubmittedEmailAddress,
        handleSubmitEmailAddress
      }}
    >
      {children}
    </EmailSignUpContext.Provider>
  )
}
