import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import CookieBanner from 'components/CookieBanner'
import {Footer as LegacyFooter} from 'components/Footer'
import {MobileOverlays} from 'components/MobileOverlays/MobileOverlays'
import cookieConfig from 'config/cookies/web2AppBottomSheet'
import {useIsPageSearchBoxOpen} from 'hooks/searchBox'
import {getIsSyntheticTest} from 'modules/meta/selectors'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {getCookie} from '@daedalus/core/src/_web/utils/cookies'
import {trackHomePageMRP} from '@daedalus/core/src/analytics/utils/trackPerformanceMetrics'
import {isReactNativeWebView} from '@daedalus/core/src/native'

import {Footer} from './Footer'
import {Header} from './Header'
import {HeroSection} from './HeroSection'
import {MobileAppSection} from './MobileAppSection'
import {PowerFeaturesSection} from './PowerFeaturesSection'
import {SavingSection} from './SavingSection'
import {SocialProofSection} from './SocialProofSection'
import {SupportSection} from './SupportSection'
import {Web2AppBottomSheet} from './Web2AppBottomSheet'
import {WidgetsSection} from './WidgetsSection'

interface Props {
  hasWidgetContext?: boolean
}
export const Home: React.FC<Props> = ({hasWidgetContext = true}) => {
  const [initialPageRender, setInitialPageRender] = useState(true)
  const dispatch = useDispatch()
  const {brand, brandIsVio} = useBrand()
  const isSearchBoxOpen = useIsPageSearchBoxOpen()
  const web2AppBottomSheetCookie = getCookie(cookieConfig.name)
  const isSyntheticTest = useSelector(getIsSyntheticTest)

  useEffect(() => {
    trackHomePageMRP(dispatch)
  }, [dispatch])

  const {ref: heroSectionRef, inView: isHeroSectionInView} = useInView({
    threshold: 0.1
  })

  const {ref: heroAndWidgetsRef, inView: isHeroAndWidgetInView} = useInView({
    threshold: 0.1,
    initialInView: true
  })

  useEffect(() => {
    setTimeout(() => setInitialPageRender(false), 400)
  }, [])

  return (
    <>
      <CookieBanner />

      <Header
        isStickyHeaderVisible={
          !initialPageRender && (!isHeroAndWidgetInView || isSearchBoxOpen)
        }
      />
      <div ref={heroAndWidgetsRef}>
        <div ref={heroSectionRef}>
          <HeroSection />
        </div>
        <WidgetsSection hasContext={hasWidgetContext} />
      </div>

      {brandIsVio ? (
        <>
          <SavingSection />
          <SocialProofSection />
          <PowerFeaturesSection />
          <MobileAppSection />
          <SupportSection />
          <Footer brand={brand} />
        </>
      ) : (
        <LegacyFooter />
      )}

      {!isReactNativeWebView() &&
        !web2AppBottomSheetCookie &&
        !isSyntheticTest && (
          <Web2AppBottomSheet
            isVisible={!initialPageRender && !isHeroSectionInView}
          />
        )}
      <MobileOverlays />
    </>
  )
}
