import {useEffect} from 'react'
import {useDispatch} from 'react-redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Entity,
  Page,
  PerformanceMetric,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {getTrackTimingEventPayload} from '@daedalus/core/src/analytics/utils/trackPerformanceMetrics'

const TRIGGERED_PERFORMANCE_METRICS = new Set()

declare global {
  interface PerformanceEntry {
    identifier?: string
    renderTime?: number
  }
}

const PerformanceMonitor = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const observer = new PerformanceObserver(list => {
      list.getEntries().forEach(entry => {
        const {identifier, renderTime} = entry

        if (
          !identifier ||
          !renderTime ||
          TRIGGERED_PERFORMANCE_METRICS.has(identifier)
        )
          return

        TRIGGERED_PERFORMANCE_METRICS.add(identifier)

        console.log(
          `%cTiming ${identifier}: ${Number(renderTime)}ms`,
          'color: #9370DB'
        )

        const [entity, name] = identifier.split('_')
        const payload = getTrackTimingEventPayload({
          metric: name as PerformanceMetric,
          entity: entity as Entity,
          team: Team.Default,
          duration: renderTime,
          page: Page.Search
        })
        dispatch(trackEvent(payload))
      })
    })

    observer.observe({type: 'element', buffered: true})

    // Disconnect the observer after 30 seconds
    // This is to prevent the observer from running indefinitely,
    // website should load in 30 seconds or less
    const loadingTimeout = setTimeout(() => {
      observer.disconnect()
    }, 30000)

    return () => {
      clearTimeout(loadingTimeout)
      observer.disconnect()
    }
  }, [dispatch])

  return null
}

export default PerformanceMonitor
