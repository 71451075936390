import {toggle} from 'opticks'

// Added as part of f72fa251-guest-reviews-languages
export const getIsTranslationAvailable = (
  reviewLanguage: string,
  languageCode: string
) => {
  if (
    languageCode.startsWith(reviewLanguage) ||
    toggle('f72fa251-guest-reviews-languages', true, false)
  )
    return false
  return true
}
