import {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {getGeolocationCoordinates} from 'modules/meta/selectors'
import {
  getAppLockedDealAnchorAudienceConfig,
  getAppLockedDealNonAnchorAudienceConfig
} from 'modules/sapiSearch/selectors'
import {invalidSearch} from 'modules/search/actions/invalidSearch'
import {
  getIsSearchBoxOpenByDefault,
  getLandingProfileId,
  getLandingQueryString
} from 'modules/search/selectors'
import {syncStoreWithUrl} from 'modules/search/slice'
import {setOpenDatePickerType} from 'modules/searchBox/slice'
import {LocationStateType} from 'types/Search'
import {
  augmentAndValidateUrlParams,
  isValidSearchParams
} from 'utils/searchParams'
import {isFsmrInQueryString, stringify} from 'utils/url'
import {convertHotelSlugToHotelId} from 'views/accommodation/Accommodation'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {getPersistedSapiLabelWithFallback} from '@daedalus/core/src/_web/sapiLabel/business'
import {
  selectIsAuthenticated,
  selectIsEmployee,
  selectIsLoading,
  selectIsMemberPlus
} from '@daedalus/core/src/auth/modules/selectors'

import {useSyncAccommodationViewOverlaysState} from './useSyncAccommodationViewOverlaysState'

interface Props {
  disallowInvalidSearchParams?: boolean
  forcedProfile?: string
}

/**
 * Hook used to sync the store with URL params
 */
export const useSyncStoreWithUrl = ({
  forcedProfile,
  disallowInvalidSearchParams = false
}: Props = {}) => {
  useSyncAccommodationViewOverlaysState()

  const dispatch = useDispatch()
  const match = useRouteMatch<{hotelSlug: string}>()
  const {isMobile, isDesktop} = useDeviceLayout()
  const location = useLocation<LocationStateType>()
  const {brandCode, brandOffersLockedByDefault, brandIsInternal} = useBrand()

  const isEmployee = useSelector(selectIsEmployee)
  const isMemberPlus = useSelector(selectIsMemberPlus)
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const isAuthLoading = useSelector(selectIsLoading)
  const landingQueryString = useSelector(getLandingQueryString)
  const landingProfileId = useSelector(getLandingProfileId)
  const isSearchBoxOpenByDefault = useSelector(getIsSearchBoxOpenByDefault)
  const isFsmr = isFsmrInQueryString(location.search)
  const geolocation = useSelector(getGeolocationCoordinates)
  const stringifiedGeolocation = JSON.stringify(geolocation)
  const anchorAppLockedAudienceConfig = useSelector(
    getAppLockedDealAnchorAudienceConfig
  )
  const nonAnchorAppLockedAudienceConfig = useSelector(
    getAppLockedDealNonAnchorAudienceConfig
  )

  const isAppLockedDealAudience =
    anchorAppLockedAudienceConfig.isAppLockedDealAudience ||
    nonAnchorAppLockedAudienceConfig.isAppLockedDealAudience ||
    isDesktop

  useEffect(() => {
    if (location.state?.openDatePickerType) {
      dispatch(setOpenDatePickerType(location.state?.openDatePickerType))
    }
  }, [dispatch, location.state?.openDatePickerType])

  useEffect(() => {
    // Get hotelId from accommodation standalone route
    const hotelIdFromSlug = convertHotelSlugToHotelId(match.params.hotelSlug)
    const augmentedUrlParams = augmentAndValidateUrlParams(location.search, {
      ...match.params,

      // If hotelId from slug is present - replace hotelId in params (standalone AP is always hotel-search context)
      ...(hotelIdFromSlug ? {hotelId: hotelIdFromSlug} : {}),
      profile: forcedProfile
    })

    const isInvalidUrlParams =
      disallowInvalidSearchParams && !isValidSearchParams(augmentedUrlParams)

    if (!isInvalidUrlParams) {
      const stringifiedAugmentedUrlParams = stringify(augmentedUrlParams)

      dispatch(
        syncStoreWithUrl({
          queryString: stringifiedAugmentedUrlParams,
          urlParams: augmentedUrlParams,
          isBrandOffersLockedByDefault: brandOffersLockedByDefault,
          isEmployee,
          isMemberPlus,
          isAuthLoading,
          isAuthenticated,
          isSearchBoxOpenByDefault,
          isFsmr,
          landingQueryString,
          landingProfileId,
          isMobile,
          sapiLabel: getPersistedSapiLabelWithFallback(
            !brandIsInternal && brandCode
          ),
          geolocation: augmentedUrlParams?.userLocationSearch
            ? geolocation
            : undefined,
          isAppLockedDealAudience
        })
      )
    } else {
      dispatch(invalidSearch())
    }
  }, [
    isAuthLoading,
    location.search,
    isMemberPlus,
    isEmployee,
    isAuthenticated,
    stringifiedGeolocation,
    brandOffersLockedByDefault,
    isAppLockedDealAudience
  ])
}
