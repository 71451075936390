import {SearchUrlParams} from 'modules/search/types'
import {isNil, reject} from 'ramda'

import {Suggestion} from '@findhotel/sapi'

export const getSearchTypeParameter = (type: string, value: string) => {
  switch (type) {
    case 'place':
      return {placeId: value}
    case 'address':
      return {address: value}
    case 'hotel':
      return {hotelId: value}
    default:
      return {}
  }
}

export const getFreeTextFilterValue = (
  value:
    | [
        {
          id: number
          value: string
        }
      ]
    | string
    | number
    | undefined
) => {
  if (Array.isArray(value)) {
    return value.map(({id}) => `${id}`)
  } else if (value) {
    return [`${value}`]
  } else {
    return undefined
  }
}

export const isValidFreeTextSearch = (params: Partial<SearchUrlParams>) =>
  Boolean(params.placeId || params.hotelId) || params.userLocationSearch === 1

export const getSearchUrlParams = (
  suggestion: Partial<Suggestion> | undefined
): Partial<SearchUrlParams> => {
  if (!suggestion) return {}
  const {filters, objectID, objectType, checkIn, checkOut, rooms} = suggestion
  const params: Partial<SearchUrlParams> = {
    ...getSearchTypeParameter(objectType, objectID),
    userLocationSearch: objectType === 'nearby' ? 1 : undefined,
    checkIn: checkIn ?? undefined,
    checkOut: checkOut ?? undefined,
    priceMax: filters?.priceMax ? Number(filters.priceMax) : undefined,
    priceMin: filters?.priceMin ? Number(filters.priceMin) : undefined,
    starRatings: getFreeTextFilterValue(filters?.starRating),
    facilities: getFreeTextFilterValue(filters?.facilities),
    themes: getFreeTextFilterValue(filters?.themes),
    propertyTypes: getFreeTextFilterValue(filters?.propertyTypes),
    rooms: rooms ?? undefined
  }
  return reject(isNil, params)
}
