import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {MAPBOX_DEFAULT_ZOOM} from 'config/map'
import handleFatalError from 'modules/common/actions/errors'
import {calculateIsAnchorRoomsOptimized} from 'modules/sapiSearch/utils'
import {RootState} from 'store'
import {GeolocationConfig} from 'types/Localization'
import {LocationStateType} from 'types/Search'
import {getLocationSearchTypeFromGeolocationSourceCookie} from 'utils/geolocation'
import {hasDates} from 'utils/trafficSource'

import {
  safelyGetStorageItem,
  safelySetStorageItem
} from '@daedalus/core/src/_web/utils/persistence'
import {Lov} from '@daedalus/core/src/analytics/types/Events'
import {GeoLocationType} from '@daedalus/core/src/place/types'
import {getPersistedGeolocationSource} from '@daedalus/core/src/utils/geolocation'
import {GeoFeatureType, Hotel} from '@findhotel/sapi'

import {
  getIsUserChangedDates,
  getQueryString,
  getUrlParams,
  isDatesDefault
} from './selectors'
import {LayoutType, SearchUrlParams} from './types'

export type {Lov, LovHit} from '@daedalus/core/src/analytics/types/Events'

export type GeoDetails = {
  shortName?: string
  featureType?: GeoFeatureType
}

type SearchData = {
  _geoloc?: GeoLocationType
  placeCategory?: number
  priceBucketWidth?: number
  searchDisplayName?: string
  geoDetails?: GeoDetails
}

export enum LocationSearchType {
  /**
   * Gets the geolocation data from the location of the ip address
   */
  IpLocation = 'ipLocation',
  /**
   * Gets the geolocation data from the user's device
   */
  UserLocation = 'userLocation',
  /**
   * Gets the geolocation data from the url
   */
  UrlLocation = 'urlLocation'
}

export type SearchStateType = {
  activeHotelId: Hotel['objectID']
  error?: Array<Record<string, unknown>>
  isDefaultDatesSearch: boolean
  isUserSearch: boolean
  isHomeSearch: boolean
  isUserInteractedWithFilters: boolean
  isNoDatesSearch: boolean
  isLandingExperience: boolean
  isHomePageLandingExperience: boolean
  isUserChangedDates: boolean
  isMapOpen: boolean
  isMapAreaSearch: boolean
  isFsmr: boolean
  isUserLocationSearch: boolean
  userLocationSearchType: LocationSearchType
  listOfValues: Lov | null | undefined
  searchData: SearchData
  queryString: string
  landingQueryString: string
  landingReferrer: string
  visitedHotels: Record<string, boolean>
  layout: LayoutType
  searchDesktopHeaderHeight: number
  isTopHeaderHidden: boolean
  showTotalPrices: boolean
  selectedAvailabilityDate: string
  isSplitBooking: boolean
  isOptimizeRooms: boolean
  isOptimizeAnchorRooms: boolean
  address: string
  freeTextSearchString: string
  mapZoomLevel: number
  forceSearch: number // Increment to explicitly trigger a new search
}

export const initialState: SearchStateType = {
  activeHotelId: null,
  isDefaultDatesSearch: false,
  isUserSearch: false,
  isHomeSearch: false,
  isUserInteractedWithFilters: false,
  isNoDatesSearch: false,
  isUserChangedDates: false,
  isMapOpen: false,
  isMapAreaSearch: false,
  isFsmr: false,
  isUserLocationSearch: false,
  userLocationSearchType: undefined,
  isLandingExperience: false,
  isHomePageLandingExperience: false,
  listOfValues: undefined,
  searchData: {},
  queryString: undefined,
  landingQueryString: undefined,
  landingReferrer: '',
  visitedHotels: JSON.parse(
    safelyGetStorageItem(
      // eslint-disable-next-line ssr-friendly/no-dom-globals-in-module-scope
      sessionStorage,
      'visitedHotels',
      'getVisitedHotels failed'
    ) || '{}'
  ),
  layout: undefined,
  searchDesktopHeaderHeight: 0,
  isTopHeaderHidden: false,
  showTotalPrices: false,
  selectedAvailabilityDate: undefined,
  isSplitBooking: false,
  isOptimizeRooms: false,
  isOptimizeAnchorRooms: false,
  address: undefined,
  mapZoomLevel: MAPBOX_DEFAULT_ZOOM,
  freeTextSearchString: '',
  forceSearch: 0
}

export interface SearchDataPayload {
  _geoloc?: GeoLocationType
  objectDisplayName?: string
  placeCategory?: number
  priceBucketWidth?: number
  geoDetails?: {
    short_name?: string
    feature_type?: GeoFeatureType
  }
}

export interface SyncStoreWithUrlPayload {
  queryString?: string
  urlParams?: SearchUrlParams
  locationState?: LocationStateType
  isBrandOffersLockedByDefault?: boolean
  isEmployee?: boolean
  isMemberPlus?: boolean
  isAuthenticated?: boolean
  isAuthLoading?: boolean
  isSearchBoxOpenByDefault?: boolean
  landingQueryString?: string
  landingProfileId?: string
  isDesktopMd?: boolean
  isMobile?: boolean
  isFsmr?: boolean
  sapiLabel?: string
  geolocation: GeolocationConfig
  isSplitBooking?: boolean
  isAppLockedDealAudience: boolean
}

export type SyncSearchWithUrlPayload = Pick<
  SyncStoreWithUrlPayload,
  'queryString' | 'urlParams' | 'isFsmr' | 'isSplitBooking'
>

interface SetListOfValuesPayload {
  listOfValues: Lov
}

interface SearchError {
  error?: Array<Record<string, unknown>>
}

const {actions, reducer} = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setListOfValues: (
      state: SearchStateType,
      {payload}: PayloadAction<SetListOfValuesPayload>
    ) => {
      state.listOfValues = payload.listOfValues
    },
    setSearchData: (
      state: SearchStateType,
      {payload}: PayloadAction<SearchDataPayload>
    ) => {
      state.searchData = {
        searchDisplayName: payload.objectDisplayName,
        _geoloc: payload._geoloc,
        placeCategory: payload.placeCategory,
        priceBucketWidth: payload.priceBucketWidth,
        geoDetails: payload?.geoDetails && {
          shortName: payload.geoDetails?.short_name,
          featureType: payload.geoDetails?.feature_type
        }
      }
    },
    resetSearchData: (state: SearchStateType) => {
      state.searchData = {}
    },
    syncStoreWithUrl: (
      state: SearchStateType,
      {payload}: PayloadAction<SyncSearchWithUrlPayload>
    ) => {
      const {urlParams, queryString, isFsmr} = payload

      state.isDefaultDatesSearch = false
      state.isNoDatesSearch = false

      if (urlParams.address) {
        state.address = urlParams.address
      }

      const currentUrlParams = getUrlParams({
        search: state
      } as RootState)
      const currentQueryString = getQueryString({search: state} as RootState)

      const queryStringHasChanged = currentQueryString !== queryString

      // Only set new queryString when changed so we can memoize any selectors based on it
      if (queryStringHasChanged) {
        state.queryString = queryString
      }

      state.isMapAreaSearch = Boolean(urlParams?.boundingBox?.length)

      state.isUserSearch = urlParams.userSearch === '1'
      state.isHomeSearch = urlParams.homeSearch === '1'

      const optimizeRooms = urlParams.optimizeRooms === '1'
      const optimizeAnchorRooms =
        urlParams.optimizeAnchorRooms !== undefined
          ? urlParams.optimizeAnchorRooms === '1'
          : undefined

      state.isOptimizeRooms = optimizeRooms || isFsmr
      state.isFsmr = isFsmr
      state.isOptimizeAnchorRooms = calculateIsAnchorRoomsOptimized(
        optimizeRooms,
        isFsmr,
        optimizeAnchorRooms
      )

      // We don't want user located searches if hotelId or placeId is present
      state.isUserLocationSearch =
        urlParams.userLocationSearch === '1' &&
        !urlParams.hotelId &&
        !urlParams.placeId

      if (state.isUserLocationSearch && !state.userLocationSearchType) {
        // We set whatever is the source from the cookie as default and only if there's no value already
        state.userLocationSearchType =
          getLocationSearchTypeFromGeolocationSourceCookie(
            getPersistedGeolocationSource()
          )
      } else if (!state.isUserLocationSearch) {
        state.userLocationSearchType = undefined
      }
      state.isUserInteractedWithFilters =
        urlParams.userInteractedWithFilters === '1'

      state.isLandingExperience = !state.isUserSearch
      if (state.isLandingExperience) {
        const isDefaultDatesSearch =
          isDatesDefault({search: state} as RootState) && !state.isUserSearch
        state.isDefaultDatesSearch = isDefaultDatesSearch
        state.isNoDatesSearch = !hasDates(urlParams)
      }

      if (urlParams.layout) state.layout = urlParams.layout

      if (state.isUserSearch && queryStringHasChanged) {
        const isUserChangedDates = getIsUserChangedDates({
          search: state
        } as RootState)

        state.isUserChangedDates =
          isUserChangedDates ||
          currentUrlParams.checkIn !== urlParams.checkIn ||
          currentUrlParams.checkOut !== urlParams.checkOut
      }

      if (state.isHomeSearch) state.isUserChangedDates = true
    },
    setVisitedHotel(state: SearchStateType, {payload}: PayloadAction<string>) {
      state.visitedHotels[payload] = true
    },
    setIsMapOpen(state: SearchStateType, {payload}: PayloadAction<boolean>) {
      state.isMapOpen = payload
    },
    setActiveHotelId(state: SearchStateType, {payload}: PayloadAction<string>) {
      state.activeHotelId = payload
    },
    setLayout(state: SearchStateType, {payload}: PayloadAction<LayoutType>) {
      state.layout = payload
    },
    setSearchDesktopHeaderHeight(
      state: SearchStateType,
      {payload}: PayloadAction<number>
    ) {
      state.searchDesktopHeaderHeight = payload
    },
    setIsTopHeaderHidden(
      state: SearchStateType,
      {payload}: PayloadAction<boolean>
    ) {
      state.isTopHeaderHidden = payload
    },
    setShowTotalPrices: (
      state: SearchStateType,
      {payload}: PayloadAction<boolean>
    ) => ({
      ...state,
      showTotalPrices: payload
    }),
    setUserLocationSearchType(
      state: SearchStateType,
      {payload}: PayloadAction<LocationSearchType>
    ) {
      state.userLocationSearchType = payload
    },
    setSelectedAvailabilityDate(
      state: SearchStateType,
      {payload}: PayloadAction<string>
    ) {
      state.selectedAvailabilityDate = payload
    },
    userSearchedFromHome(state: SearchStateType) {
      state.isHomeSearch = true
      state.isUserSearch = true
    },

    setFreeTextSearchString: (
      state: SearchStateType,
      {payload}: PayloadAction<string>
    ) => ({
      ...state,
      freeTextSearchString: payload
    }),
    setMapZoomLevel: (
      state: SearchStateType,
      {payload}: PayloadAction<number>
    ) => ({
      ...state,
      mapZoomLevel: payload
    }),
    forceSearch: state => {
      state.forceSearch = state.forceSearch + 1
    }
  },
  extraReducers: {
    [handleFatalError.type]: (
      state: SearchStateType,
      action: PayloadAction<SearchError>
    ) => {
      state.error = action.payload.error
    }
  }
})

export const setShowTotalPrices = (
  showTotalPrices: boolean,
  persist = true
) => {
  if (persist)
    safelySetStorageItem(
      localStorage,
      'showTotalPrices',
      showTotalPrices.toString()
    )
  return actions.setShowTotalPrices(showTotalPrices)
}

export const setVisitedHotel = (hotelId: string) => {
  if (hotelId) {
    const currentVisitedHotels = JSON.parse(
      safelyGetStorageItem(
        sessionStorage,
        'visitedHotels',
        'getVisitedHotels failed'
      ) || '{}'
    )

    currentVisitedHotels[hotelId] = true

    safelySetStorageItem(
      sessionStorage,
      'visitedHotels',
      JSON.stringify(currentVisitedHotels),
      'setVisitedHotel failed'
    )
  }

  return actions.setVisitedHotel(hotelId)
}

export type SetListOfValuesAction = ReturnType<typeof actions.setListOfValues>

export const {
  syncStoreWithUrl,
  setSearchData,
  resetSearchData,
  setListOfValues,
  setIsMapOpen,
  setActiveHotelId,
  setLayout,
  setSearchDesktopHeaderHeight,
  setIsTopHeaderHidden,
  setUserLocationSearchType,
  setSelectedAvailabilityDate,
  userSearchedFromHome,
  setMapZoomLevel,
  setFreeTextSearchString,
  forceSearch
} = actions

export default reducer
