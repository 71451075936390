import React, {useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {useConditionalEffect} from 'hooks/useConditionalEffect'

import {BottomSheet} from '@daedalus/atlas/BottomSheet'
import {Button} from '@daedalus/atlas/Button'
import {Pill} from '@daedalus/atlas/Pill'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {ReviewSortingMobileContentLoadable} from './Loadable'
import {sortLabels, sortList as reviewSortList, sortList} from './ReviewSorting'

interface Props {
  onSortChange: (value: string) => void
  onSortClick: () => void
}

// Added as part of 9633c72c-guest-reviews-sort-filter experiment
export const ReviewSortingMobile: React.FC<Props> = ({
  onSortChange,
  onSortClick
}) => {
  useConditionalEffect(
    async () =>
      import(
        /* webpackPrefetch: true */
        /* webpackChunkName: "ReviewSortingMobileContent" */
        './ReviewSortingMobileContent'
      )
  )

  const {formatMessage} = useIntl()
  const [isOpen, setIsOpen] = useState(false)
  const [sort, setSortValue] = useState(reviewSortList[0].value)

  const handleSortChange = (value: string) => {
    setSortValue(value)
  }

  const handleClick = () => {
    setIsOpen(true)
    onSortClick()
  }

  const handleOverlayClose = () => {
    setIsOpen(false)
  }

  const handleSubmit = () => {
    onSortChange(sort)
    handleOverlayClose()
  }

  const sortMessage = useMemo(() => {
    const {key} = sortList.find(item => item.value === sort)
    return sortLabels[key]
  }, [sort])

  return (
    <>
      <Pill showArrow onClick={handleClick}>
        {formatMessage(sortMessage)}
      </Pill>
      <BottomSheet
        titleBarProps={{
          centerContent: (
            <FormattedMessageWrapper id="sortBy" defaultMessage="Sort by" />
          )
        }}
        bottomBarContent={
          <Button fullWidth onClick={handleSubmit}>
            <FormattedMessageWrapper id="apply" defaultMessage="Apply" />
          </Button>
        }
        isOpen={isOpen}
        onClose={handleOverlayClose}
      >
        {isOpen && (
          <ReviewSortingMobileContentLoadable
            onChange={handleSortChange}
            currentValue={sort}
          />
        )}
      </BottomSheet>
    </>
  )
}
