import {createSlice} from '@reduxjs/toolkit'

import type {PayloadAction} from '@reduxjs/toolkit'

interface PriceWatchState {
  isHoldOutGroup: boolean
}

const initialState = {
  isHoldOutGroup: false
} as PriceWatchState

const {actions, reducer} = createSlice({
  name: 'priceWatch',
  initialState,
  reducers: {
    setHoldOutGroup(state: PriceWatchState, action: PayloadAction<boolean>) {
      state.isHoldOutGroup = action.payload
    }
  }
})

export default reducer

export const {setHoldOutGroup} = actions
