import {useSelector} from 'react-redux'
import {differenceInDays} from 'date-fns'
import {ulid} from 'ulid'

import {SIZES} from '@daedalus/atlas/Image'
import {imageProvider} from '@daedalus/atlas/testutils/imageProvider'
import {getAnonymousId} from '@daedalus/core/src/_web/anonymousId'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {customerIo} from '@daedalus/core/src/analytics/services/CustomerIo'
import {Rate} from '@daedalus/core/src/analytics/services/CustomerIo/types'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  getCognitoUserTier,
  selectUserId
} from '@daedalus/core/src/auth/modules/selectors'
import {getEpochSeconds, getTimezone} from '@daedalus/core/src/utils/date'

import {
  useCancelPriceWatchMutation,
  useCreateAnonymousPriceWatchTrackingMutation,
  useGetUserPriceWatchTrackingsQuery
} from '../services'
import {PriceWatchFlow, PriceWatchMetric} from '../types'

import type {ContentHotel, Hotel} from '@findhotel/sapi'

export interface UsePriceWatchProps {
  checkIn: string
  checkOut: string
  rooms: string
  currency: string
  locale: string
  hotel: Hotel | ContentHotel
  rate: Rate
  onPriceWatchInitialized?: (flowType?: string) => void
  roomName?: string
}

export const usePriceWatch = ({
  checkIn,
  checkOut,
  rooms,
  currency,
  locale,
  hotel,
  rate,
  onPriceWatchInitialized,
  roomName
}: UsePriceWatchProps) => {
  const {brand} = useBrand()
  const cio = customerIo(brand)
  const userId = useSelector(selectUserId)
  const anonymousId = getAnonymousId() as string

  const nights = differenceInDays(new Date(checkOut), new Date(checkIn))

  const {data: userPriceWatchTrackings = []} =
    useGetUserPriceWatchTrackingsQuery(
      {userId: userId || anonymousId},
      {skip: !userId && !anonymousId}
    )

  const priceWatchEntry = (userPriceWatchTrackings || []).find(
    tracking =>
      tracking?.hotelId === hotel?.objectID &&
      tracking?.checkIn === checkIn &&
      tracking?.checkOut === checkOut &&
      tracking?.currency === currency &&
      tracking?.rooms === rooms
  )

  const priceWatchId = priceWatchEntry?.priceWatchId
  const isEnabled = priceWatchEntry?.metric !== PriceWatchMetric.Paused
  const isPriceWatchOn = Boolean(priceWatchId) && isEnabled

  const hotelImageUrl = hotel?.imageURIs?.[0]
    ? imageProvider(hotel?.imageURIs[0], SIZES['large'])
    : undefined

  const [cancelPriceWatch] = useCancelPriceWatchMutation()
  const [createPriceWatch] = useCreateAnonymousPriceWatchTrackingMutation()

  const tiers = getCognitoUserTier()

  const initPriceWatch = async (flowType?: string) => {
    const priceWatchId = ulid()
    try {
      onPriceWatchInitialized?.(flowType)
      await createPriceWatch({
        priceWatchId,
        userId: userId || anonymousId,
        checkIn,
        checkOut,
        hotelId: hotel.objectID,
        rooms,
        userCountry: 'US',
        device: 'mobile',
        source: 'frontend',
        flow: PriceWatchFlow.EXPLICIT,
        nights,
        tiers,
        hotel: {
          name: hotel?.hotelName || '',
          starRating: hotel?.starRating || 0,
          image: hotelImageUrl || ''
        },
        currency,
        locale,
        rate: {
          ...rate,
          totalRate: rate.totalRate!,
          originalTotalRate: rate.totalRate!,
          lowestAlertedRate: rate.totalRate!,
          taxesIncluded: rate.taxesIncluded!,
          localTaxesIncluded: rate.localTaxesIncluded!
        },
        timezone: getTimezone()
      }).unwrap()

      cio.trackEvent({
        name: `${Category.User}_${Entity.SubscribeToPriceWatch}_${Action.Submitted}`,
        anonymousId,
        userId,
        eventId: priceWatchId,
        checkIn: Number(getEpochSeconds(checkIn)),
        checkOut: Number(getEpochSeconds(checkOut)),
        hotelId: hotel.objectID,
        rooms,
        currency,
        locale,
        hotelImageUrl,
        rate,
        starRating: hotel?.starRating,
        hotelName: hotel?.hotelName,
        hotelAddress: hotel?.displayAddress,
        timezone: getTimezone(),
        roomName,
        type: 'push'
      })
    } catch (error) {
      console.error('Error creating price watch:', error)
    }
  }

  const handleCancelPriceWatch = async ({remove = false} = {}) => {
    const queryParams = new URLSearchParams(location.search)
    const urlPriceWatchId = queryParams.get('priceWatchId')
    const priceWatchIdWithFallback = priceWatchId || urlPriceWatchId
    if (!priceWatchIdWithFallback) throw new Error('No priceWatchId found')

    try {
      await cancelPriceWatch({
        priceWatchId: priceWatchIdWithFallback,
        userId: userId || anonymousId,
        remove
      }).unwrap()
    } catch (error) {
      console.error('Error canceling PriceWatch', error)
    }
  }

  return {
    priceWatchEntry,
    isPriceWatchOn,
    initPriceWatch,
    cancelPriceWatch: handleCancelPriceWatch,
    userPriceWatchTrackings
  }
}
