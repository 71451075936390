import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {getAnonymousId} from 'modules/meta/selectors'

import {selectIsAuthenticated} from '@daedalus/core/src/auth/modules/selectors'
import {isReactNativeWebView} from '@daedalus/core/src/native/isReactNativeWebView'
import {useMergePriceWatchTrackingsMutation} from '@daedalus/shared/src/priceWatch/services'

export const PriceWatchTrackingsSync = () => {
  const anonymousId = useSelector(getAnonymousId)
  const isReactNative = isReactNativeWebView()
  const isAuthenticated = useSelector(selectIsAuthenticated)
  const [mergePriceWatchTrackings] = useMergePriceWatchTrackingsMutation()

  useEffect(() => {
    isReactNative && isAuthenticated && mergePriceWatchTrackings({anonymousId})
  }, [isReactNative, isAuthenticated, mergePriceWatchTrackings, anonymousId])

  return null
}
