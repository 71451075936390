import React, {useEffect, useState} from 'react'

import {loadModule} from '../../utils/loadModule'
import {useEmailSignUpContext} from './EmailSignUpProvider'

export const MobileLoadable = loadModule(
  async () =>
    import(
      /* webpackChunkName: "EmailMobile" */
      './components/Mobile'
    ),
  {}
)

export const EmailFlow = () => {
  const {emailAddress, isOpen} = useEmailSignUpContext()

  useEffect(() => {
    if (isOpen) {
      //   dispatch(
      //     trackEvent({
      //       category: Category.System,
      //       entity: Entity.SMSOverlay,
      //       action: Action.Displayed,
      //       team: Team.Retention
      //     })
      //   )
    }
  }, [isOpen, emailAddress])

  const [mountChildren, setMountChildren] = useState(isOpen)
  useEffect(() => {
    if (isOpen) setMountChildren(isOpen)
  }, [isOpen])

  // Defer loading child components until opened once
  if (!mountChildren) return null

  return <MobileLoadable />
}
