import React from 'react'
import {useSelector} from 'react-redux'
import {AvailabilityComponent} from 'components/AvailabilityDatePicker/useAvailability'
import {useIsRequiredOneTimePasswordFlow} from 'hooks/useIsRequiredOneTimePasswordFlow'
import {getAppLockedDealConfig} from 'modules/sapiSearch/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {
  Entity,
  Page,
  PerformanceMetric
} from '@daedalus/core/src/analytics/types/Events'
import {hasOfferTag} from '@daedalus/core/src/offer/business/offers'
import {OfferTagLabel, RateChecker} from '@daedalus/core/src/offer/types/offer'
import {OfferPrimaryLayoutVariant} from '@daedalus/core/src/offer/types/OfferLayout'
import {OfferVariant} from '@daedalus/core/src/offer/types/OfferVariant'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {OfferLayout} from '@daedalus/shared/src/search/OfferLayout'
import TrackPerformanceTiming from '@daedalus/shared/src/utils/PerformanceMonitor/TrackPerformanceTiming'

import {useOfferHasTag} from '../hooks/useOfferHasTag'
import {OfferInteraction} from '../Shared/OfferInteraction'
import {ProviderLogoComponent} from '../Shared/ProviderLogoComponent'
import {RoomLabelsComponent} from '../Shared/RoomLabelsComponent'
import {SplitBookingOfferInteraction} from '../Shared/SplitBookingOfferInteraction'
import {CtaComponent} from './CtaComponent'
import {PriceComponent} from './PriceComponent'
import {SplitBookingPrimaryOffer} from './SplitBookingPrimaryOffer'
import {TagComponent} from './TagComponent/TagComponent'

interface MainProps {
  isLoading?: false
  variant: OfferPrimaryLayoutVariant
  hotelId: string
  offer: SearchOffer
  anchorPrice: number
  anchorPriceTotal?: number
  offerVariant?: OfferVariant
  onLabelClick?: () => void
  hasRoomLabels?: boolean
  isSplitBooking?: false
  splitBookingType?: never
  isYourChoice?: boolean
  showSignInToUnlock?: boolean
  shouldShowTaxation?: boolean
  shouldShowTaxationAndNightly?: boolean
  shouldShowCtaLockIcon?: boolean
  showPriceIndication?: boolean
  sourceComponent?: string
  offerPosition?: number
  hasInteraction: boolean
  splitBookingOffers?: never
  splitBookingBundle?: never
  hasWeb2AppPromo?: boolean
}

interface LoadingProps {
  isLoading: true
  variant: OfferPrimaryLayoutVariant
  hasInteraction?: false
  hotelId?: never
  offer?: never
  splitBookingOffers?: never
  splitBookingBundle?: never
  anchorPrice?: never
  anchorPriceTotal?: never
  offerVariant?: never
  onLabelClick?: never
  isSplitBooking?: boolean
  splitBookingType?: never
  showSignInToUnlock?: never
  shouldShowTaxation?: never
  shouldShowTaxationAndNightly?: never
  shouldShowCtaLockIcon?: never
  showPriceIndication?: never
  hasRoomLabels?: never
  sourceComponent?: never
  offerPosition?: never
  hasWeb2AppPromo?: never
}

type SplitBookingProps = React.ComponentProps<typeof SplitBookingPrimaryOffer>

export const PrimaryOffer: React.FC<
  MainProps | SplitBookingProps | LoadingProps
> = ({
  variant = 'PrimaryDefault',
  hotelId,
  offer,
  splitBookingOffers = [],
  splitBookingBundle,
  offerVariant,
  anchorPrice,
  isLoading = false,
  isSplitBooking,
  splitBookingType,
  showSignInToUnlock = false,
  offerPosition = 0,
  onLabelClick,
  shouldShowTaxation = true,
  shouldShowTaxationAndNightly = false,
  shouldShowCtaLockIcon = true,
  anchorPriceTotal,
  hasRoomLabels = true,
  showPriceIndication = false,
  sourceComponent,
  hasInteraction = true,
  hasWeb2AppPromo = false
}) => {
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isRequiredOneTimePasswordFlow = useIsRequiredOneTimePasswordFlow({
    offer,
    isAppLockedDeal
  })

  const offerHasTag = useOfferHasTag(offerVariant)
  const isYourChoice = hasOfferTag(
    splitBookingBundle || offer,
    OfferTagLabel.IsYourChoice
  )

  if (isLoading) {
    return <OfferLayout variant={variant} isLoading />
  }

  if (isSplitBooking) {
    return (
      <SplitBookingOfferInteraction
        component={sourceComponent}
        hotelId={hotelId}
      >
        <Box
          display="inline"
          data-id={isYourChoice && RateChecker.GHAYourChoiceId}
        >
          <SplitBookingPrimaryOffer
            isSplitBooking
            anchorPrice={anchorPrice}
            anchorPriceTotal={anchorPriceTotal}
            variant={variant}
            hotelId={hotelId}
            splitBookingOffers={splitBookingOffers}
            offerVariant={offerVariant}
            splitBookingType={splitBookingType}
            onLabelClick={onLabelClick}
            shouldShowTaxation={shouldShowTaxation}
            shouldShowTaxationAndNightly={shouldShowTaxationAndNightly}
            hasRoomLabels={hasRoomLabels}
          />
        </Box>
      </SplitBookingOfferInteraction>
    )
  }
  return (
    <OfferInteraction
      anchorPriceTotal={anchorPriceTotal}
      hotelId={hotelId}
      offer={offer}
      component={sourceComponent}
      offerPosition={offerPosition}
      hasInteraction={hasInteraction}
    >
      <TrackPerformanceTiming
        entity={Entity.Offer}
        name={PerformanceMetric.LRP}
        page={Page.SearchResults}
      />
      <Box
        display="inline"
        data-id={isYourChoice && RateChecker.GHAYourChoiceId}
      >
        <OfferLayout
          variant={variant}
          isLoading={isLoading}
          tagComponent={
            offerHasTag ? (
              <TagComponent
                anchorPrice={anchorPrice}
                anchorPriceTotal={anchorPriceTotal}
                hotelId={hotelId}
                offer={offer}
                offerVariant={offerVariant}
                onTagClick={onLabelClick}
                variant={variant}
              />
            ) : null
          }
          priceComponent={
            <PriceComponent
              hotelId={hotelId}
              showSignInToUnlock={showSignInToUnlock}
              anchorPrice={anchorPrice}
              offer={offer}
              offerVariant={offerVariant}
              shouldShowTaxation={shouldShowTaxation}
              shouldShowTaxationAndNightly={shouldShowTaxationAndNightly}
              anchorPriceTotal={anchorPriceTotal}
              variant={variant}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
              showPriceIndication={showPriceIndication}
              forceMobileLayout={
                sourceComponent ===
                AvailabilityComponent.DesktopAvailabilityCalendar
              }
            />
          }
          providerLogoComponent={
            <ProviderLogoComponent
              offer={offer}
              variant={variant}
              isAppLockedDeal={isAppLockedDeal}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
            />
          }
          roomLabelsComponent={
            <RoomLabelsComponent
              offer={offer}
              hasRoomLabels={hasRoomLabels}
              hotelId={hotelId}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
            />
          }
          ctaComponent={
            <CtaComponent
              offer={offer}
              hasWeb2AppPromo={hasWeb2AppPromo}
              hotelId={hotelId}
              variant={variant}
              showLockIcon={shouldShowCtaLockIcon}
              isRequiredOneTimePasswordFlow={isRequiredOneTimePasswordFlow}
            />
          }
        />
      </Box>
    </OfferInteraction>
  )
}
