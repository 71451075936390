import {Rate} from '@daedalus/core/src/analytics/services/CustomerIo/types'
import {
  ParametrizedHotelContext,
  SearchContext
} from '@daedalus/core/src/analytics/types/Events'

import {PriceWatchBell} from '../components/PriceWatchBell'
import {PriceWatchToggle} from '../components/PriceWatchToggle/PriceWatchToggle'

import type {ContentHotel, Hotel} from '@findhotel/sapi'

export interface PriceWatchCTAParams {
  checkIn: string
  checkOut: string
  rooms: string
  roomsNumber: number
  currency: string
  locale: string
  hotel: Hotel | ContentHotel
  rate: Rate
  onPriceWatchInitialized?: () => void
  isAuthenticated: boolean
  getSearchContext: (
    state: Record<string, string | number>,
    searchIdParam?: string
  ) => SearchContext
  hotelContext?: ParametrizedHotelContext
  roomName?: string
  ctaComponent: typeof PriceWatchToggle | typeof PriceWatchBell
  shouldRedirectToAp: boolean
  guests: number
  setWebBottomSheetOpen: () => void
  shouldPulse?: boolean
  alertsManagementUrl?: string
  componentName: string
}

export enum PriceWatchFlow {
  IMPLICIT = 'implicit',
  EXPLICIT = 'explicit',
  DEALFREEZE = 'dealfreeze'
}

export enum PriceWatchMetric {
  Created = 'created',
  Changed = 'changed',
  Sent = 'sent',
  Delivered = 'delivered',
  Opened = 'opened',
  Clicked = 'clicked',
  Paused = 'paused',
  Resumed = 'resumed'
}

export interface PriceWatchPayload {
  priceWatchId: string
  userId: string
  hotelId: string
  checkIn: string // ISO date string
  checkOut: string // ISO date string
  rooms: string
  userCountry: string
  device: string
  currency: string
  locale: string
  source: string
  flow: PriceWatchFlow
  timezone: string
  tiers: string[]
  nights: number
  eventId: string
  metric: PriceWatchMetric
  createdAt: string // ISO date string
  rate: {
    base: number
    hotelFees: number
    localTaxesIncluded: boolean
    taxes: number
    taxesIncluded: boolean
    totalRate: number
    originalTotalRate: number
    lowestAlertedRate: number
  }
  hotel: {
    name: string
    starRating: number
    city: string
    image: string
  }
  variation: string
  utcOffset: number
}

export interface CreatePriceWatch {
  priceWatchId: string
  userId: string
  hotelId: string
  checkIn: string
  checkOut: string
  rooms: string
  userCountry: string
  device: string
  currency: string
  locale: string
  source: string
  flow: PriceWatchFlow
  timezone: string
  rate: {
    totalRate: number
    taxesIncluded: boolean
    localTaxesIncluded: boolean
    base?: number
    hotelFees?: number
    taxes?: number
    originalTotalRate: number
    lowestAlertedRate: number
  }
  hotel?: {
    name: string
    starRating: string | number
    city?: string
    image: string
  }
  room?: {
    name?: string
    roomId: string
    masterId?: string
  }
  tiers?: string[]
  variation?: string
  isFreeCancellation?: boolean
  utcOffset?: number
  nights: number
}
