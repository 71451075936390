import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import {
  OnDestinationChangeParams,
  RecentSearchParams,
  SearchLocationType,
  useSearchBox,
  useSearchBoxDestinationPicker
} from 'hooks/searchBox'
import {hideOverlay} from 'modules/overlay/slice'
import {
  getSuggestions,
  getSuggestionSearchValue
} from 'modules/searchBox/selectors'
import {closeSearchBox, setSuggestions} from 'modules/searchBox/slice'
import {buildUserSuggestions} from 'services/search/Suggests'
import {getSearchUrlParams, isValidFreeTextSearch} from 'utils/freeTextSearch'
import {GeolocationSource} from 'utils/geolocation'
import {removeUserSearchHistoryById} from 'utils/persistence'

import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {SuggestionItemDataType} from '@daedalus/core/src/autocomplete/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {isReactNativeWebView} from '@daedalus/core/src/native'
import {
  getPersistedGeolocation,
  getPersistedGeolocationSource
} from '@daedalus/core/src/utils/geolocation'

import {NoResults} from './NoResults'
import {
  SuggestionsListElement,
  SuggestionsListSection,
  UserHistoryHeadingElement
} from './styles'
import {SuggestionItem} from './SuggestionItem'
import {SuggestionNearby} from './SuggestionNearby'
import {getShowNearbySuggestion} from './utils'

export enum SuggestionsListVariant {
  Overlay = 'overlay',
  Popover = 'popover'
}

export interface Props {
  onClose?: () => void
  onChange: (params: OnDestinationChangeParams) => void
  onRecentSearchSelected: (params: RecentSearchParams) => void
  onSuggestionSelected: () => void
  onNearbyPropertiesSuggestionSelected: () => void
  listType?: SuggestionsListVariant
}

export const SuggestionsList: React.FC<Props> = ({
  onClose,
  onChange,
  onRecentSearchSelected,
  onSuggestionSelected,
  onNearbyPropertiesSuggestionSelected,
  listType = SuggestionsListVariant.Overlay
}) => {
  const {doSearch} = useSearchBox()
  const dispatch = useDispatch()
  const {isMobile} = useDeviceLayout()

  const suggestionsData = useSelector(getSuggestions)
  const suggestionsSearchValue = useSelector(getSuggestionSearchValue)
  const [selectedSuggestion, setSelectedSuggestion] = useState(-1)

  const {destinationPickerInputDisplayValue} = useSearchBoxDestinationPicker()

  useEffect(() => {
    setSelectedSuggestion(-1)
  }, [suggestionsData])

  const flattenedSuggestions = useMemo(() => {
    return suggestionsData.reduce(
      (acc, {suggestions}) => [...acc, ...suggestions],
      []
    )
  }, [suggestionsData])

  useEffect(() => {
    // As we're using this component in desktop now, add keyboard controls to this component
    // Will make it opt-in for now
    if (listType !== 'popover') {
      return
    }

    const keyboardHandler = (e: KeyboardEvent) => {
      if (e.key === 'ArrowUp') {
        setSelectedSuggestion(Math.max(selectedSuggestion - 1, -1))
      }

      if (e.key === 'ArrowDown') {
        setSelectedSuggestion(
          Math.min(selectedSuggestion + 1, flattenedSuggestions.length - 1)
        )
      }

      if (e.key === 'Enter') {
        const index = Math.max(selectedSuggestion, 0)
        const suggestion = flattenedSuggestions[index]
        if (suggestion) {
          handleSuggestionClick(suggestion, index)()
        }
      }
    }

    document.addEventListener('keydown', keyboardHandler)

    return () => {
      document.removeEventListener('keydown', keyboardHandler)
    }
  }, [listType, selectedSuggestion, suggestionsData])

  const handleSuggestionClick = useCallback(
    (suggestion: SuggestionItemDataType, index: number) => () => {
      const {
        isUserSearchHistory,
        objectDisplayName,
        objectID,
        objectType,
        objectName,
        checkIn,
        checkOut,
        rooms,
        isFreeTextSearch
      } = suggestion

      if (isUserSearchHistory) {
        if (checkIn && checkOut && rooms) {
          const searchHistoryContext = {
            checkIn,
            checkOut,
            hotelId: objectType === 'hotel' ? objectID : undefined,
            placeId: objectType === 'place' ? objectID : undefined,
            address: objectType === 'address' ? objectName : undefined,
            rooms
          }

          dispatch(
            trackEvent({
              category: Category.User,
              entity: Entity.DestinationPickerSearchHistoryItem,
              action: Action.Clicked,
              analyticsContext: {
                [AnalyticsContext.SearchHistoryContext]: searchHistoryContext,
                [AnalyticsContext.AutocompleteContext]: {
                  suggestion,
                  position: index + 1
                }
              }
            })
          )

          onRecentSearchSelected({
            type: objectType as SearchLocationType,
            value: objectType === 'address' ? objectName : objectID,
            checkIn,
            checkOut,
            rooms
          })
        }
      } else if (isFreeTextSearch) {
        const newUrlParams = getSearchUrlParams(suggestion)
        const isValidSearch = isValidFreeTextSearch(newUrlParams)
        if (isValidSearch) {
          dispatch(
            trackEvent({
              category: Category.User,
              entity: Entity.DestinationPickerFreeTextSearchItem,
              action: Action.Clicked,
              analyticsContext: {
                [AnalyticsContext.AutocompleteContext]: {
                  suggestion,
                  position: index + 1,
                  inputtedString: suggestionsSearchValue
                }
              }
            })
          )
          doSearch({
            newUrlParams,
            searchOptions: {method: 'replace'},
            isFreeTextSearch: true
          })
          setTimeout(() => {
            dispatch(hideOverlay())
            dispatch(closeSearchBox())
            onClose?.()
          }, 500)
        }
      } else {
        dispatch(
          trackEvent({
            category: Category.User,
            entity: Entity.DestinationPickerAutocompleteItem,
            action: Action.Clicked,
            analyticsContext: {
              [AnalyticsContext.AutocompleteContext]: {
                suggestion,
                position: index + 1,
                inputtedString: suggestionsSearchValue
              }
            }
          })
        )

        onChange({
          displayValue: objectDisplayName,
          value: objectType === 'address' ? objectName : objectID,
          type: objectType as SearchLocationType
        })

        onSuggestionSelected()
      }
    },
    [
      onRecentSearchSelected,
      onChange,
      onSuggestionSelected,
      dispatch,
      doSearch,
      onClose,
      suggestionsSearchValue
    ]
  )

  useEffect(() => {
    const autoCompleteItems = suggestionsData
      .filter(({type}) => type === 'apiSuggestions')
      .flatMap(({suggestions}) => suggestions)

    if (suggestionsSearchValue.length === 0 && autoCompleteItems.length === 0) {
      return
    }

    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.DestinationPickerAutoCompleteItems,
        action: Action.Displayed,
        payload: {
          autoCompleteItems,
          inputtedString: suggestionsSearchValue
        }
      })
    )
  }, [dispatch, suggestionsData, suggestionsSearchValue])

  const isReactNative = isReactNativeWebView()
  const geolocation = getPersistedGeolocation()
  const geolocationSource = getPersistedGeolocationSource() as GeolocationSource

  const showNearbySuggestion = getShowNearbySuggestion({
    isReactNative,
    geolocation,
    geolocationSource,
    suggestionsSearchValue,
    destinationPickerInputDisplayValue
  })

  const handleSuggestionListUpdate = () => {
    const userSuggestionData = buildUserSuggestions()

    // This part of the code removes the old userSuggestion and replace it with a new one
    const cleanedData = suggestionsData.filter(
      ({type}) => type !== 'userSearchHistorySuggestions'
    )

    // We need to dispatch the new list in order to don't loos any results on the list
    dispatch(
      setSuggestions({
        suggestions: [...userSuggestionData, ...cleanedData],
        suggestionsSearchValue: ''
      })
    )
  }

  const handleRemoveHistoryClick = historyId => {
    removeUserSearchHistoryById(historyId)
    handleSuggestionListUpdate()
  }

  if (!suggestionsData.length && !showNearbySuggestion)
    return <NoResults listType={listType} />

  return (
    <SuggestionsListElement
      className={cx(
        listType === 'overlay' && '__isOverlay',
        listType === 'popover' && '__isPopover'
      )}
    >
      {showNearbySuggestion && (
        <SuggestionNearby onClick={onNearbyPropertiesSuggestionSelected} />
      )}

      {suggestionsData?.map(({suggestions, type}, sectionIndex) => {
        const sectionOffset = suggestionsData
          .slice(0, sectionIndex)
          .reduce((acc, {suggestions}) => acc + suggestions.length, 0)

        return (
          <SuggestionsListSection key={type} listType={listType}>
            {isMobile && type === 'userSearchHistorySuggestions' && (
              <UserHistoryHeadingElement>
                <FormattedMessageWrapper
                  id="recentSearches"
                  defaultMessage="Recent searches"
                />
              </UserHistoryHeadingElement>
            )}
            {suggestions.map((suggestion, i) => (
              <div
                key={[suggestion.objectID, suggestion.objectName, i].join('_')}
                onClick={handleSuggestionClick(suggestion, i)}
                role="presentation"
              >
                <SuggestionItem
                  suggestionDetails={suggestion}
                  isFocused={selectedSuggestion === sectionOffset + i}
                  onUserHistoryCloseIconClick={() =>
                    handleRemoveHistoryClick(suggestion.historyId ?? '')
                  }
                />
              </div>
            ))}
          </SuggestionsListSection>
        )
      })}
    </SuggestionsListElement>
  )
}
